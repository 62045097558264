import { useEffect, useState } from "react";
import { useMUD } from "../../store";
import { Entity, Has, HasValue, getComponentValue, getComponentValueStrict } from "@latticexyz/recs";
import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { ClickWrapper } from "./Theme/ClickWrapper";
import { BrutalistCard } from "./Theme/BrutalistCard";
import { CreateMatch } from "./CreateMatch";
import { Balances } from "./Balances";
import { Button } from "./Theme/SkyStrife/Button";
import { Hex, formatEther, hexToString } from "viem";
import { useMatchRewards } from "../amalgema-ui/hooks/useMatchRewards";
import { ordinalSuffix } from "../amalgema-ui/MatchRewardsFooter";
import { useAccount } from "wagmi";
import { NetworkStatus } from "../amalgema-ui/NetworkStatus";
import { TopPlayers } from "./TopPlayers";

const CopyButton = ({ match }: { match: Entity }) => {
  const {
    networkLayer: {
      network: {
        components: { LevelTemplates, MatchConfig, MatchMapCopyProgress },
        worldContract,
      },
    },
  } = useMUD();

  const progress = useComponentValue(MatchMapCopyProgress, match);
  const { levelId } = getComponentValueStrict(MatchConfig, match);
  const templateIds = useComponentValue(LevelTemplates, levelId as Entity);

  const fraction = progress && templateIds ? Math.round(Number(progress.value * 100n) / templateIds.value.length) : 0;

  return (
    <Button
      buttonType={"primary"}
      onClick={() => {
        worldContract.write.copyMap([match as Hex]);
      }}
    >
      Copy ({fraction}%)
    </Button>
  );
};

const Row = ({ match }: { match: Entity }) => {
  const {
    networkLayer: {
      components: { Match, MatchConfig, MatchReady, SpawnReservedBy },
      network: { worldContract },
      utils: { getLevelSpawns },
    },
  } = useMUD();

  const matchId = getComponentValueStrict(Match, match).value;
  const matchConfig = getComponentValue(MatchConfig, match);

  const spawns = matchConfig ? getLevelSpawns(matchConfig.levelId) : [];

  const readyTime = useComponentValue(MatchReady, match);
  const reserved = useEntityQuery([Has(SpawnReservedBy), HasValue(Match, { value: matchId })]);
  const levelName = matchConfig?.levelId ? hexToString(matchConfig.levelId as Hex, { size: 32 }) : "N/A";

  const rewards = useMatchRewards(matchId);

  return (
    <tr key={matchId}>
      <td>Match #{matchId}</td>
      <td>{levelName}</td>
      <td>
        {reserved.length} / {spawns.length}
      </td>
      <td>
        {rewards.length > 0
          ? rewards.map((reward) => (
              <div key={reward.rank.toString()}>
                {ordinalSuffix(Number(reward.rank + 1n))}: {reward.emoji} {formatEther(reward.value)}
              </div>
            ))
          : "None"}
      </td>
      <td>
        {readyTime ? (
          // append existing URL params
          <a href={`/match${window.location.search}${window.location.search.length === 0 ? "?" : "&"}match=${matchId}`}>
            <Button buttonType={"primary"}>Join</Button>
          </a>
        ) : (
          <CopyButton match={match} />
        )}
        <Button
          buttonType="secondary"
          onClick={() => {
            worldContract.write.adminDestroyMatch([match as Hex]);
          }}
        >
          Destroy Match
        </Button>
      </td>
    </tr>
  );
};

export const Browser = () => {
  const {
    networkLayer: {
      components: { Match, MatchConfig },
      network: { playerEntity, initialiseWallet },
    },
  } = useMUD();

  const [visible, setVisible] = useState(false);
  const allMatches = useEntityQuery([Has(Match), Has(MatchConfig)]);

  const { address } = useAccount();

  useEffect(() => {
    initialiseWallet(address);
  }, [address, initialiseWallet]);

  return (
    <div className="fixed h-screen w-screen">
      <ClickWrapper>
        <BrutalistCard>
          {playerEntity && <Balances player={playerEntity} />}
          <div className="p-4 h-[600px] overflow-scroll">
            <div className="w-full text-3xl text-left p-1">Top players</div>
            <TopPlayers />
            <div className="flex flex-row">
              <div className="w-full text-3xl text-left p-1">Lobbies</div>
              <Button buttonType={"secondary"} onClick={() => setVisible(true)}>
                Create match
              </Button>
            </div>
            <table className="w-full text-lg text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xl text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th>Lobby</th>
                  <th>Map</th>
                  <th>Players</th>
                  <th>Rewards</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allMatches.map((match) => (
                  <Row key={match} match={match} />
                ))}
              </tbody>
            </table>
          </div>
          <NetworkStatus />
        </BrutalistCard>
      </ClickWrapper>
      {visible && <CreateMatch visible={visible} setVisible={setVisible} />}
    </div>
  );
};
