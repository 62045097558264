import useLocalStorageState from "use-local-storage-state";
import { MouseTooltip } from "./amalgema-ui/MouseTooltip";
import { Card } from "./ui/Theme/SkyStrife/Card";
import { useState } from "react";

export function AnalyticsConsentForm() {
  const [showAnalyticsTooltip, setShowAnalyticsTooltip] = useState(false);
  const [analyticsConsent, setAnalyticsConsent] = useLocalStorageState("analytics-consent", {
    defaultValue: true,
  });

  return (
    <>
      <div className="flex flex-row justify-around">
        <label
          style={{
            borderBottom: "2px dotted #000",
          }}
          onMouseEnter={() => setShowAnalyticsTooltip(true)}
          onMouseLeave={() => setShowAnalyticsTooltip(false)}
          htmlFor="analytics-consent"
          className=""
        >
          I consent to send client gameplay analytics to help improve Sky Strife.
        </label>
        <input
          id="analytics-consent"
          type="checkbox"
          className=""
          checked={analyticsConsent}
          onChange={(e) => setAnalyticsConsent(e.target.checked)}
        />
      </div>

      <MouseTooltip showTooltip={showAnalyticsTooltip}>
        <Card
          className="w-[320px]"
          style={{
            boxShadow:
              "2px 2px 0px 0px #181710, 2px 2px 8px 0px rgba(24, 23, 16, 0.12), 2px 2px 16px 0px rgba(24, 23, 16, 0.08)",
            zIndex: 100,
          }}
        >
          Gameplay analytics contain information about the transactions your client attempts to send to the blockchain.
          We use this information to improve the stability, cost, and performance of Sky Strife. We do not collect any
          personal or identifying information.
        </Card>
      </MouseTooltip>
    </>
  );
}
