import { useEffect, useRef, useState } from "react";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Body, Caption, OverlineLarge, OverlineSmall } from "../ui/Theme/SkyStrife/Typography";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { useAmalgema } from "../../store";
import { Plus } from "../ui/Theme/SkyStrife/Icons/Plus";
import { useCurrentMatchReward } from "./hooks/useCurrentMatchReward";
import { useTokenBalances } from "./hooks/useTokenBalances";
import { Entity } from "@latticexyz/recs";
import { useComponentValue } from "@latticexyz/react";
import { Mana } from "../ui/Theme/SkyStrife/Mana";
import { addressToEntityID } from "../../mud/setupNetwork";
import { ordinalSuffix } from "./MatchRewardsFooter";
import { CrossIcon } from "../ui/Theme/CrossIcon";
import useOnClickOutside from "../ui/hooks/useOnClickOutside";
import { SummonPrivateMatch } from "./SummonPrivate";
import { SummonPublicMatch } from "./SummonPublic";

const DENOMINATOR = 100n;

export function SummonIslandModal({ close }: { close: () => void }) {
  const {
    network: {
      components: { SkyPoolConfig },
      singletonEntity,
    },
    externalWalletClient,
  } = useAmalgema();

  const [isPublic, setIsPublic] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close();
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [close]);

  useOnClickOutside(ref, close);

  const matchReward = useCurrentMatchReward();
  const tokenBalances = useTokenBalances(
    externalWalletClient && externalWalletClient.account
      ? addressToEntityID(externalWalletClient.account.address)
      : ("0" as Entity)
  );
  const skyPoolConfig = useComponentValue(SkyPoolConfig, singletonEntity);
  if (!skyPoolConfig) {
    return <div>No config...</div>;
  }
  const { cost, token, matchRewardNumerators } = skyPoolConfig;

  const userSkyPoolTokenBalance = tokenBalances.find((tokenBalance) => tokenBalance.token === token)?.value ?? 0n;
  const insufficientBalance = userSkyPoolTokenBalance < cost;

  return (
    <div
      style={{
        background: "rgba(24, 23, 16, 0.65)",
        zIndex: 100,
      }}
      className="fixed top-0 left-0 w-screen h-screen flex flex-col justify-around"
    >
      <div ref={ref} className="mx-auto">
        <Card primary className="bg-ss-bg-1 flex flex-col items-center p-8 w-[624px] max-h-screen overflow-y-scroll">
          <div className="flex flex-col w-full">
            <div className="flex w-full justify-between">
              <OverlineLarge>Summon Island</OverlineLarge>

              <div className="w-10" />

              <Button buttonType={"tertiary"} onClick={close} className="h-fit">
                <CrossIcon />
              </Button>
            </div>

            <div className="h-4" />

            <div className="flex flex-row w-full">
              <div className="flex flex-col">
                <div className="flex flex-row w-[500px]">
                  <div className="grow w-full mb-3">
                    <OverlineSmall className="w-full text-left">Your Resources</OverlineSmall>
                    <Mana amount={userSkyPoolTokenBalance} />
                  </div>

                  <div className="grow w-full mb-3">
                    <OverlineSmall className="w-full text-left">Match Creation Cost</OverlineSmall>
                    <Mana amount={cost} />
                  </div>
                </div>

                <div className="h-4" />

                <div className="grow w-full mb-3">
                  <OverlineSmall className="w-full text-left">Reward Pool</OverlineSmall>
                  <Caption>
                    The reward pool varies based on the number of matches played in the past week. More matches, less
                    rewards.
                  </Caption>
                  <div className="h-3"></div>
                  {matchRewardNumerators &&
                    matchRewardNumerators
                      .filter((numerator) => numerator > 0)
                      .map((numerator, i) => (
                        <div key={i} className="flex justify-between w-full">
                          <Body className="underline mb-1">{ordinalSuffix(i + 1)} place</Body>
                          <Mana amount={(numerator * matchReward) / DENOMINATOR} />
                        </div>
                      ))}
                </div>
                <div className="h-8"></div>
                <div className="w-full border border-ss-stroke h-[1px]"></div>
                <div className="h-8"></div>
                <div className="grow w-full mb-3">
                  <OverlineSmall className="w-full text-left">Match Type</OverlineSmall>
                  <div className="flex flex-row">
                    <div>
                      <input
                        id="set-public"
                        type="radio"
                        checked={isPublic}
                        onChange={(e) => setIsPublic(e.target.checked)}
                      />
                      <label htmlFor="set-public" className="ml-2">
                        Public match
                      </label>
                    </div>

                    <div className="w-[96px]" />

                    <div>
                      <input
                        id="set-private"
                        type="radio"
                        checked={!isPublic}
                        onChange={(e) => setIsPublic(!e.target.checked)}
                      />
                      <label htmlFor="set-private" className="ml-2">
                        Private match
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  {isPublic ? (
                    <SummonPublicMatch insufficientBalance={insufficientBalance} close={close} />
                  ) : (
                    <SummonPrivateMatch insufficientBalance={insufficientBalance} close={close} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export function useSummonIslandModal() {
  const [modalOpen, setModalOpen] = useState(false);

  return {
    modalOpen,
    setModalOpen,
    modal: modalOpen ? <SummonIslandModal close={() => setModalOpen(false)} /> : <></>,
  };
}

export function SummonIsland() {
  const { setModalOpen, modal } = useSummonIslandModal();

  return (
    <>
      {modal}

      <div className="flex flex-row items-center justify-between">
        <div>
          <OverlineLarge>Play</OverlineLarge>
          <Caption>Create and join matches of Sky Strife</Caption>
        </div>

        <Button buttonType={"primary"} size={"lg"} onClick={() => setModalOpen(true)}>
          <div className="flex flex-row items-center justify-center h-fit">
            <Plus /> <div className="w-4" /> <span>Summon Island</span>
          </div>
        </Button>
      </div>
    </>
  );
}
