import { useEntityQuery } from "@latticexyz/react";
import { Entity, Has, getComponentValue, getComponentValueStrict } from "@latticexyz/recs";
import { useAmalgema } from "../../../store";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { isDefined } from "@latticexyz/common/utils";

export function useTokenBalances(playerEntity: Entity) {
  const {
    components: { TokenBalance, TokenMetadata },
  } = useAmalgema();

  const balances = useEntityQuery([Has(TokenBalance)])
    .map((key) => {
      const { token, entity } = decodeEntity(TokenBalance.metadata.keySchema, key);
      if (entity !== playerEntity) return;

      const metadata = getComponentValue(TokenMetadata, token as Entity);
      if (!metadata) return;
      const { name, emoji, decimals } = metadata;

      const { value } = getComponentValueStrict(TokenBalance, key);

      return { token, name, emoji, decimals, value };
    })
    .filter(isDefined);

  return balances;
}
