import {
  Entity,
  Has,
  HasValue,
  defineEnterSystem,
  defineUpdateSystem,
  getComponentValueStrict,
  setComponent,
} from "@latticexyz/recs";
import { HeadlessLayer } from "../..";
import { TerrainTypes } from "../../../Network";

export function createScopeClientToMatchSystem(layer: HeadlessLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Match, MatchConfig, MoveDifficulty, Position, TerrainType },
        network: { match },
        utils: { getVirtualLevelData },
      },
    },
    components: { InCurrentMatch },
  } = layer;

  defineEnterSystem(world, [HasValue(Match, { value: match })], ({ entity }) => {
    setComponent(InCurrentMatch, entity, { value: true });
  });

  defineEnterSystem(world, [Has(Position)], ({ entity }) => {
    const position = getComponentValueStrict(Position, entity);
    if (position.z !== match) return;

    setComponent(InCurrentMatch, entity, { value: true });
  });

  defineUpdateSystem(world, [Has(Position)], ({ entity }) => {
    const position = getComponentValueStrict(Position, entity);
    if (position.z !== match) return;

    setComponent(InCurrentMatch, entity, { value: true });
  });

  defineEnterSystem(world, [Has(MatchConfig), HasValue(Match, { value: match })], ({ entity }) => {
    const { levelId } = getComponentValueStrict(MatchConfig, entity);

    const data = getVirtualLevelData(levelId as Entity);

    // Create client-side entities for virtual templates
    data.forEach((datum, i) => {
      setComponent(MoveDifficulty, `levelIndex:${i}` as Entity, datum.MoveDifficulty);

      // Custom z-coordinate for match
      setComponent(Position, `levelIndex:${i}` as Entity, { ...datum.Position, z: match });

      // Custom setting for enums
      setComponent(TerrainType, `levelIndex:${i}` as Entity, {
        value: TerrainTypes[TerrainTypes[datum.TerrainType.value]] as never,
      });
    });
  });
}
