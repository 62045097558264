import {
  Component,
  Entity,
  Has,
  HasValue,
  Type,
  getComponentValue,
  getComponentValueStrict,
  hasComponent,
  runQuery,
} from "@latticexyz/recs";
import { setup } from "../../mud/setup";
import { BigNumber } from "ethers";
import { WorldCoord } from "phaserx/src/types";
import { manhattan } from "../../utils/distance";
import { Hex, stringToHex } from "viem";
import { NetworkConfig } from "../../mud/utils";
import { encodeEntity } from "@latticexyz/store-sync/recs";
import { createSystemExecutor } from "./createSystemExecutor";
import { createTransactionCacheSystem } from "./systems/TransactionCacheSystem";
import { TransactionDB } from "./TransactionDB";
import { decodeValue } from "@latticexyz/protocol-parser";
import { hexToResource } from "@latticexyz/common";
import { KeySchema } from "@latticexyz/protocol-parser";

const BYTES32_ZERO = "0x0000000000000000000000000000000000000000000000000000000000000000";
const SPAWN_SETTLEMENT = stringToHex("SpawnSettlement", { size: 32 });

/**
 * The Network layer is the lowest layer in the client architecture.
 * Its purpose is to synchronize the client components with the contract components.
 */
export async function createNetworkLayer(config: NetworkConfig) {
  const { network, components } = await setup(config);
  const { worldContract, match: currentMatchId, playerEntity } = network;

  const isBrowser = typeof window !== "undefined";

  const getAnalyticsConsent = () => {
    if (!isBrowser) return false;

    const consent = localStorage.getItem("analytics-consent");
    return consent === "true";
  };

  const { executeSystem, executeSystemWithExternalWallet } = createSystemExecutor({
    worldContract,
    network,
    components,
    sendAnalytics: getAnalyticsConsent(),
  });

  function scopePathToMatch(path: WorldCoord[], matchId: number = currentMatchId) {
    return path.map((coord) => ({ x: coord.x, y: coord.y, z: matchId }));
  }

  async function move(entity: Entity, path: WorldCoord[]) {
    console.log(`Moving entity ${entity} to position (${path[path.length - 1].x}, ${path[path.length - 1].y})}`);
    await executeSystem({
      entity,
      systemCall: "move",
      args: [[entity as Hex, scopePathToMatch(path)]],
    });
  }

  async function moveAndAttack(attacker: Entity, path: WorldCoord[], defender: Entity) {
    console.log(
      `Moving entity ${attacker} to position (${path[path.length - 1].x}, ${
        path[path.length - 1].y
      }) and attacking entity ${defender}`
    );

    await executeSystem({
      entity: attacker,
      systemCall: "moveAndAttack",
      args: [[attacker as Hex, scopePathToMatch(path), defender as Hex]],
    });
  }

  async function attack(attacker: Entity, defender: Entity) {
    console.log(`Attacking entity ${defender} with entity ${attacker}`);

    await executeSystem({
      entity: attacker,
      systemCall: "fight",
      args: [[attacker as Hex, defender as Hex]],
    });
  }

  async function buildAt(
    builderId: Entity,
    prototypeId: Entity,
    position: WorldCoord,
    matchId: number = currentMatchId
  ) {
    console.log(`Building prototype ${prototypeId} at ${JSON.stringify(position)}`);

    await executeSystem({
      entity: builderId,
      systemCall: "build",
      args: [[builderId as Hex, prototypeId as Hex, { x: position.x, y: position.y, z: matchId }]],
    });
  }

  async function spawnTemplateAt(
    prototypeId: Entity,
    position: WorldCoord,
    {
      owner,
      matchId,
    }: {
      owner?: Entity;
      matchId?: number;
    }
  ) {
    console.log(`Spawning prototype ${prototypeId} at ${JSON.stringify(position)}`);
    return await worldContract.write.spawnPrototypeDev([
      prototypeId as Hex,
      owner ? (owner as Hex) : BYTES32_ZERO,
      { x: position.x, y: position.y, z: matchId ?? currentMatchId },
    ]);
  }

  function findEntityWithComponentInRelationshipChain(
    relationshipComponent: Component<{ value: Type.String }>,
    entity: Entity,
    searchComponent: Component
  ): Entity | undefined {
    if (hasComponent(searchComponent, entity)) return entity;

    while (hasComponent(relationshipComponent, entity)) {
      const entityValue = getComponentValueStrict(relationshipComponent, entity).value as Entity;
      if (entityValue == null) return;
      entity = entityValue;

      if (hasComponent(searchComponent, entity)) return entity;
    }

    return;
  }

  function getOwningPlayer(entity: Entity): Entity | undefined {
    return findEntityWithComponentInRelationshipChain(components.OwnedBy, entity, components.Player);
  }

  function isOwnedBy(entity: Entity, player: Entity) {
    const owningPlayer = getOwningPlayer(entity);
    return owningPlayer && owningPlayer === player;
  }

  function getPlayerEntity(address: string | undefined, matchId: number = currentMatchId): Entity | undefined {
    if (!address) return;

    const addressEntity = address as Entity;
    const playerEntity = [
      ...runQuery([
        HasValue(components.OwnedBy, { value: addressEntity }),
        Has(components.Player),
        HasValue(components.Match, { value: matchId }),
      ]),
    ][0];

    return playerEntity;
  }

  function getCurrentPlayerEntity() {
    return getPlayerEntity(playerEntity);
  }

  function isOwnedByCurrentPlayer(entity: Entity) {
    const player = getPlayerEntity(playerEntity);
    return player && isOwnedBy(entity, player);
  }

  const findClosest = (entity: Entity, searchEntities: Entity[]) => {
    const closestEntity: {
      distance: number;
      Entity: Entity | null;
    } = {
      distance: Infinity,
      Entity: null,
    };

    const entityPosition = getComponentValue(components.Position, entity);
    if (!entityPosition) return closestEntity;

    for (const searchEntity of searchEntities) {
      const searchPosition = getComponentValue(components.Position, searchEntity);
      if (!searchPosition) continue;

      const distance = manhattan(entityPosition, searchPosition);
      if (distance < closestEntity.distance) {
        closestEntity.distance = distance;
        closestEntity.Entity = searchEntity;
      }
    }

    return closestEntity;
  };

  const getMatchEntity = (_matchId: number) => {
    if (!_matchId) return;

    const matchEntity = [
      ...runQuery([Has(components.MatchConfig), HasValue(components.Match, { value: _matchId })]),
    ][0];
    if (matchEntity == null) return;

    return matchEntity;
  };

  const getMatchConfig = (_matchId: number) => {
    const matchEntity = getMatchEntity(_matchId);
    if (matchEntity == null) return;

    return getComponentValue(components.MatchConfig, matchEntity);
  };

  const getCurrentMatchConfig = () => {
    return getMatchConfig(currentMatchId);
  };

  const getTurnAtTime = (_matchId: number, time: number) => {
    const matchConfig = getMatchConfig(_matchId);
    if (!matchConfig) return -1;

    const startTime = BigNumber.from(matchConfig.startTime);
    const turnLength = BigNumber.from(matchConfig.turnLength);

    let atTime = BigNumber.from(time);
    if (atTime < startTime) atTime = startTime;

    return atTime.sub(startTime).div(turnLength).toNumber();
  };

  const getTurnAtTimeForCurrentMatch = (time: number) => {
    return getTurnAtTime(currentMatchId, time);
  };

  const getLevelSpawns = (levelId: string) => {
    const { LevelTemplates } = components;

    const templateIds = getComponentValue(LevelTemplates, levelId as Entity);

    if (!templateIds) {
      return [];
    }

    const initialValue: bigint[] = [];
    return templateIds.value.reduce(
      (c, templateId, i) => (templateId === SPAWN_SETTLEMENT ? c.concat(BigInt(i)) : c),
      initialValue
    );
  };

  const getAvailableLevelSpawns = (levelId: string, matchEntity: Hex) => {
    const { SpawnReservedBy } = components;

    return getLevelSpawns(levelId).filter((index) => {
      const reserved = hasComponent(
        SpawnReservedBy,
        encodeEntity(SpawnReservedBy.metadata.keySchema, { matchEntity, index })
      );

      return !reserved;
    });
  };

  const matchIsLive = (entity: Entity) => {
    const { Match, MatchConfig, MatchReady, Player } = components;

    const matchId = getComponentValue(Match, entity)?.value;

    const playersInMatch = runQuery([Has(Player), HasValue(Match, { value: matchId })]);

    const { levelId } = getComponentValueStrict(MatchConfig, entity);
    const spawns = getLevelSpawns(levelId);

    return playersInMatch.size === spawns.length && getComponentValue(MatchReady, entity);
  };

  function decodeData(tableId: Hex, staticData: Hex) {
    const { name } = hexToResource(tableId as Hex);
    const component = components[name as keyof typeof components];

    // Workaround, custom decoding for user-defined types
    return ["TerrainType", "StructureType", "UnitType"].includes(name)
      ? decodeValue({ value: "uint8" }, staticData as Hex)
      : decodeValue(component.metadata?.valueSchema, staticData as Hex);
  }

  function getTemplateValueStrict(tableId: Hex, templateId: Hex) {
    // Workaround, custom key schema for user-defined types
    const keySchema: KeySchema = {
      ...components.TemplateContent.metadata.keySchema,
      tableId: "bytes32",
    };

    const { staticData } = getComponentValueStrict(
      components.TemplateContent,
      encodeEntity(keySchema, { templateId, tableId })
    );

    return decodeData(tableId, staticData as Hex);
  }

  function getLevelOverrideValueStrict(tableId: Hex, levelId: Hex, index: bigint) {
    // Workaround, custom key schema for user-defined types
    const keySchema: KeySchema = {
      ...components.LevelContent.metadata.keySchema,
      tableId: "bytes32",
    };

    const { staticData } = getComponentValueStrict(
      components.LevelContent,
      encodeEntity(keySchema, {
        levelId,
        index,
        tableId,
      })
    );

    return decodeData(tableId, staticData as Hex);
  }

  function getLevelDatum(levelId: Hex, index: bigint) {
    const templateId = getComponentValueStrict(components.LevelTemplates, levelId as Entity).value[Number(index)];

    const componentValues: Record<string, any> = {};

    const { value: templateTableIds } = getComponentValueStrict(components.TemplateTables, templateId as Entity);
    templateTableIds.forEach((tableId) => {
      const { name } = hexToResource(tableId as Hex);

      componentValues[name] = getTemplateValueStrict(tableId as Hex, templateId as Hex);
    });

    const { value: overrideTableIds } = getComponentValueStrict(
      components.LevelTables,
      encodeEntity(components.LevelTables.metadata.keySchema, { levelId, index })
    );
    overrideTableIds.forEach((tableId) => {
      const { name } = hexToResource(tableId as Hex);

      componentValues[name] = getLevelOverrideValueStrict(tableId as Hex, levelId, index);
    });

    return componentValues;
  }

  // Get the data for all level indices that have a virtual template
  function getVirtualLevelData(levelId: Entity) {
    const { value: templateIds } = getComponentValueStrict(components.LevelTemplates, levelId);

    const initialValue: Record<string, any>[] = [];
    return templateIds.reduce((result, templateId, i) => {
      if (hasComponent(components.VirtualLevelTemplates, templateId as Entity)) {
        result.push(getLevelDatum(levelId as Hex, BigInt(i)));
      }
      return result;
    }, initialValue);
  }

  const layer = {
    world: network.world,
    network,
    components: {
      ...network.components,
      ...components,
    },

    executeSystem,
    executeSystemWithExternalWallet,

    api: {
      getMatchEntity,
      getMatchConfig,
      getCurrentMatchConfig,

      move,
      moveAndAttack,
      attack,
      buildAt,

      dev: {
        spawnTemplateAt,
      },
    },
    utils: {
      findClosest,

      getTurnAtTime,
      getTurnAtTimeForCurrentMatch,

      getOwningPlayer,
      isOwnedBy,
      isOwnedByCurrentPlayer,
      getPlayerEntity,
      getCurrentPlayerEntity,

      manhattan,

      getTemplateValueStrict,

      getLevelSpawns,
      getAvailableLevelSpawns,
      matchIsLive,

      getVirtualLevelData,
      getAnalyticsConsent,
    },
    isBrowser,
  };

  const indexedDbAvailable = isBrowser && "indexedDB" in window;
  if (indexedDbAvailable) {
    const txDb = new TransactionDB(network.networkConfig.worldAddress, network.networkConfig.chainId);
    createTransactionCacheSystem(layer, txDb);
  }

  return layer;
}
