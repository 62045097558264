import { useEntityQuery } from "@latticexyz/react";
import { Entity, Has, getComponentValueStrict } from "@latticexyz/recs";
import { useMUD } from "../../store";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { decodeValue, KeySchema } from "@latticexyz/protocol-parser";
import { Hex } from "viem";
import { range } from "@latticexyz/utils";

const TransparentImage = () => (
  <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" width="20" />
);

export const DisplayLevel = ({ level }: { level: Entity }) => {
  const {
    networkLayer: {
      components: { Position, LevelContent },
    },
  } = useMUD();

  const positions = useEntityQuery([Has(LevelContent)])
    .filter((key) => {
      // Workaround, custom key schema for user-defined types
      const keySchema: KeySchema = {
        ...LevelContent.metadata.keySchema,
        tableId: "bytes32",
      };

      const { levelId } = decodeEntity(keySchema, key);
      return levelId === level;
    })
    .map((key) => {
      const { staticData } = getComponentValueStrict(LevelContent, key);

      return decodeValue(Position.metadata.valueSchema, staticData as Hex);
    });

  return (
    <div className="bg-blue-300">
      <table>
        <tbody>
          {Array.from(range(30, 1, -15)).map((j) => (
            <tr key={j}>
              {Array.from(range(30, 1, -15)).map((i) => (
                <td key={`${i},${j}`} className="p-0">
                  {positions.some(({ x, y }) => x === i && y === j) ? (
                    <img src="./assets/grass.png" width="20" />
                  ) : (
                    <TransparentImage />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
