import { Entity, getComponentValue, removeComponent, setComponent } from "@latticexyz/recs";
import { PhaserLayer } from "../..";
import { UnitTypeAnimations, UnitTypeAttackAnimations, UnitTypeDeathAnimations } from "../../phaserConstants";

export function createCombatSystem(layer: PhaserLayer) {
  const {
    parentLayers: {
      network: {
        components: { UnitType, StructureType, Combat },
      },
      local: {
        components: { LocalHealth, LocalPosition, Capturer },
        api: {
          getOwnerColor,
          systemDecoders: { onCombat },
        },
      },
      headless: {
        components: { PreviousOwner },
        api: {
          combat: { canRetaliate },
        },
      },
    },
    scenes: {
      Main: { objectPool },
    },
    animations: { triggerBloodSplatter },
    api: { playTintedAnimation },
  } = layer;

  function playAttackAnimation(
    entity: Entity,
    entityOwner: Entity,
    {
      onStart,
      onContact,
      onComplete,
    }: {
      onStart?: (sprite?: Phaser.GameObjects.Sprite) => void;
      onContact?: (sprite?: Phaser.GameObjects.Sprite) => void;
      onComplete?: (sprite?: Phaser.GameObjects.Sprite) => void;
    }
  ) {
    const unitType = getComponentValue(UnitType, entity)?.value;
    if (!unitType) {
      if (onStart) onStart();
      if (onContact) onContact();
      if (onComplete) onComplete();
      return;
    }
    const attackAnimation = UnitTypeAttackAnimations[unitType];

    const idleAnimation = UnitTypeAnimations[unitType];

    const embodiedObject = objectPool.get(entity, "Sprite");
    const ownerColor = getOwnerColor(entityOwner);
    const tintedAnimation = playTintedAnimation(entity, attackAnimation, ownerColor.name);
    embodiedObject.setComponent({
      id: "attack-animation",
      now: (sprite) => {
        if (!attackAnimation) {
          if (onComplete) onComplete(sprite);
          return;
        }

        let started = false;
        const onAttackUpdate = (anim: Phaser.Animations.Animation, frame: Phaser.Animations.AnimationFrame) => {
          if (anim.key !== tintedAnimation) return;

          if (!started && onStart) {
            onStart(sprite);
            started = true;
          }

          if (frame.progress >= 1) {
            playTintedAnimation(entity, idleAnimation, ownerColor.name);
          }
          if (onContact && frame.index === 5) onContact(sprite);
          if (onComplete && frame.progress >= 1) {
            onComplete(sprite);
            sprite.removeListener("animationupdate", onAttackUpdate);
          }
        };

        sprite.on(`animationupdate`, onAttackUpdate);
      },
    });
  }

  function playDeathAnimation(entity: Entity, entityOwner: Entity, onDeath: () => void) {
    const unitType = getComponentValue(UnitType, entity)?.value;
    if (!unitType) {
      onDeath();
      return;
    }

    const deathAnimation = UnitTypeDeathAnimations[unitType];
    const ownerColor = getOwnerColor(entityOwner);
    playTintedAnimation(entity, deathAnimation, ownerColor.name);

    const embodiedObject = objectPool.get(entity, "Sprite");
    embodiedObject.setComponent({
      id: "death-animation",
      now: (sprite) => {
        sprite.on(`animationcomplete`, () => {
          onDeath();
        });
      },
    });
  }

  onCombat(
    ({
      attacker,
      attackerDied,
      attackerDamageReceived,
      defenderDamageReceived,
      defender,
      defenderDied,
      ranged,
      defenderCaptured,
    }) => {
      const attackerPosition = getComponentValue(LocalPosition, attacker);
      if (!attackerPosition) return;

      const defenderPosition = getComponentValue(LocalPosition, defender);
      if (!defenderPosition) return;

      const attackerOwner = getComponentValue(PreviousOwner, attacker)?.value ?? ("0" as Entity);
      const defenderOwner = getComponentValue(PreviousOwner, defender)?.value ?? ("0" as Entity);

      const defenderIsStructure = getComponentValue(StructureType, defender);
      const flipAttacker = defenderPosition.x < attackerPosition.x;
      const flipDefender = attackerPosition.x < defenderPosition.x;

      const attackerTookDamage = attackerDamageReceived > 0;
      const defenderTookDamage = defenderDamageReceived > 0;

      const attackerHealth = getComponentValue(Combat, attacker)?.health || 0;
      const defenderHealth = getComponentValue(Combat, defender)?.health || 0;

      playAttackAnimation(attacker, attackerOwner, {
        onStart: (sprite) => {
          if (sprite) sprite.flipX = flipAttacker;
        },
        onContact: () => {
          if (attackerDied) {
            setComponent(LocalHealth, attacker, { value: 0 });
          } else if (attackerTookDamage) {
            setComponent(LocalHealth, attacker, { value: attackerHealth });
          }

          if (attackerDied || attackerTookDamage) {
            triggerBloodSplatter(attackerPosition);
          }

          if (defenderDied) {
            setComponent(LocalHealth, defender, { value: 0 });
          } else if (defenderTookDamage) {
            setComponent(LocalHealth, defender, { value: defenderHealth });
          }

          if (defenderDied || defenderTookDamage) {
            if (!defenderIsStructure) {
              triggerBloodSplatter(defenderPosition);
            }
          }

          if (defenderCaptured) {
            setComponent(Capturer, defender, { value: attacker });
          }
        },
        onComplete: (sprite) => {
          if (sprite && flipAttacker) sprite.flipX = false;

          if (attackerDied) {
            playDeathAnimation(attacker, attackerOwner, () => {
              removeComponent(LocalHealth, attacker);
              removeComponent(LocalPosition, attacker);
            });
          }

          if (defenderDied) {
            playDeathAnimation(defender, defenderOwner, () => {
              removeComponent(LocalHealth, defender);
              removeComponent(LocalPosition, defender);
            });
          }
        },
      });
      if (!ranged && canRetaliate(layer.parentLayers.network, defender))
        playAttackAnimation(defender, defenderOwner, {
          onStart: (sprite) => {
            if (sprite) sprite.flipX = flipDefender;
          },
          onComplete: (sprite) => {
            if (sprite && flipDefender) sprite.flipX = false;
          },
        });
    }
  );
}
