import { mudConfig, resolveTableId } from "@latticexyz/world/register";
import { stringToHex } from "viem";
import { TemplatesConfig } from "./ts/templates/templateConfig";
import { LevelsConfig } from "./ts/levels/levelConfig";
import debug from "../../maps/debug.json" assert { type: "json" };

const config = mudConfig({
  userTypes: {
    PackedCounter: { filePath: "@latticexyz/store/src/PackedCounter.sol", internalType: "bytes32" },
    ResourceId: { filePath: "@latticexyz/store/src/ResourceId.sol", internalType: "bytes32" },
  },
  enums: {
    UnitTypes: [
      "Unknown",
      "Swordsman",
      "Pikeman",
      "Golem",
      "Rider",
      "Knight",
      "Dragon",
      "Archer",
      "Catapult",
      "Wizard",
    ],
    TerrainTypes: [
      "Unknown",
      "Grass",
      "Mountain",
      "Water",
      "Wall",
      "Forest",
      "StoneWall",
      "LavaGround",
      "LavaMountain",
      "LavaForest",
      "Lava",
      "RockWall",
    ],
    StructureTypes: [
      "Unknown",
      "Settlement",
      "SpawnSettlement",
      "GoldShrine",
      "EscapePortal",
      "Portal",
      "Container",
      "SummoningAltar",
      "BlazingHeartShrine",
      "WoodenWall",
      "GoldMine",
      "Village",
      "EmberCrownShrine",
      "CrystalGenerator",
      "MetalGenerator",
      "FossilGenerator",
      "WidgetGenerator",
    ],
    ItemTypes: [
      "Unknown",
      "Gold",
      "EmberCrown",
      "BlazingHeart",
      "MovementBanner",
      "SwordBanner",
      "StaminaBanner",
      "Crystal",
      "Metal",
      "Fossil",
      "Widget",
    ],
  },
  overrideSystems: {
    AttackSystem: {
      openAccess: false,
      accessList: [],
      name: "AttackSystem",
    },
    FinishSystem: {
      openAccess: false,
      accessList: [],
      name: "FinishSystem",
    },
  },
  tables: {
    /**
     * Marks an entity as an admin. Used on address entities.
     */
    Admin: {
      valueSchema: "bool",
    },
    /**
     * Used on terrain to modify the armor of entities standing on it.
     */
    ArmorModifier: {
      valueSchema: "int32",
    },
    /**
     * The address that recieves the rewards of a given player in a match.
     */
    Recipient: {
      valueSchema: "bytes32",
    },
    /**
     * Marks an entity as capturable.
     * Instead of dying, they will return to full health
     * and change ownership to the capturer.
     */
    Capturable: {
      valueSchema: "bool",
    },
    /**
     * Marks an entity as able to capture other entities.
     */
    Capturer: {
      valueSchema: "bool",
    },
    /**
     * The time at which charging started. This is used to determine
     * how much stamina to recharge when refreshing the charged unit in the
     * future.
     * Charger => StartTime
     */
    ChargedByStart: {
      valueSchema: "uint256",
    },
    /**
     * References the entity that is being charged.
     * Charger => Chargee
     */
    Chargee: {
      valueSchema: "bytes32",
    },
    /**
     * Sets an entity as a charger. The value here is
     * added to the total amount of stamina recharged
     * when the target entity is refreshed.
     */
    Charger: {
      valueSchema: "int32",
    },
    /**
     * Used to track the total amount of stamina recharged by a Charger.
     * Used to implement depletable Gold Mines.
     */
    ChargeCap: {
      valueSchema: {
        cap: "int32",
        totalCharged: "int32",
      },
    },
    /**
     * If an entity has this it is able to engage in combat.
     * All values represented in thousands.
     * i.e. 100_000 HP = 100 HP
     */
    Combat: {
      valueSchema: {
        health: "int32",
        maxHealth: "int32",
        armor: "int32",
        strength: "int32",
        structureStrength: "int32",
        counterStrength: "int32",
      },
    },
    /**
     * The amount of Stamina (Gold) a Player receives when killing a unit.
     */
    StaminaOnKill: {
      valueSchema: "int32",
    },
    /**
     * Emitted during combat to inform client animations.
     */
    CombatResult: {
      offchainOnly: true,
      valueSchema: {
        attacker: "bytes32",
        defender: "bytes32",
        attackerDamageReceived: "int32",
        defenderDamageReceived: "int32",
        attackerHealth: "int32",
        defenderHealth: "int32",
        attackerDamage: "int32",
        defenderDamage: "int32",
        ranged: "bool",
        attackerDied: "bool",
        defenderDied: "bool",
        defenderCaptured: "bool",
      },
    },
    /**
     * Used on terrain to give Strength bonuses to any entity staning on it.
     */
    StrengthMod: {
      valueSchema: "int32",
    },
    /**
     * Marks something as an Escape Portal. Can be used in the Escape system.
     */
    EscapePortal: {
      valueSchema: "bool",
    },
    /**
     * Marks an entity as able to construct other entities.
     */
    Factory: {
      valueSchema: {
        prototypeIds: "bytes32[]",
        staminaCosts: "int32[]",
      },
    },
    /**
     * Used to mark something as an Item.
     * NOTE: Only use this to determine if something is an item contract-side.
     * Specific Item Types are only used client-side to deteremine rendering.
     */
    ItemType: {
      valueSchema: "ItemTypes",
    },
    /**
     * How many kills something has.
     */
    KillCount: {
      valueSchema: "uint32",
    },
    /**
     * Used in conjuction with Stamina to lazily calculate Stamina regen.
     */
    LastAction: {
      valueSchema: "uint256",
    },
    /**
     * Match ID.
     * Used to tag entitities as part of a specific match.
     */
    Match: "uint32",
    /**
     * Used to keep track of all the instantiated SpawnPoints in a match.
     */
    MatchSpawnPoints: "bytes32[]",
    /**
     * Kept up to date with all of the player entities present in a Match.
     */
    MatchPlayers: "bytes32[]",
    /**
     * Match data for SkyPool
     */
    MatchSky: {
      valueSchema: {
        blockNumber: "uint256",
        reward: "uint256",
      },
    },
    /**
     * SkyPool settings:
     * - Creation cost of SkyPool matches.
     * - Window (in blocks) to determine match rewards.
     * - The entity that holds the SkyPools balance.
     * - Token that is used in SkyPool rewards.
     * - The fraction that each place in the match should win
     */
    SkyPoolConfig: {
      keySchema: {},
      valueSchema: {
        cost: "uint256",
        window: "uint256",
        entity: "bytes32",
        token: "bytes32",
        matchRewardNumerators: "uint256[]",
      },
    },
    /**
     * Match gameplay settings.
     */
    MatchConfig: {
      valueSchema: {
        startTime: "uint256",
        turnLength: "uint256",
        actionCooldownLength: "uint256",
        levelId: "bytes32",
        createdBy: "bytes32",
      },
    },
    /**
     * Match access control resource and function selector.
     */
    MatchAccessControl: {
      valueSchema: {
        resourceSelector: "bytes32",
        funcSelector: "bytes4",
      },
      dataStruct: false,
    },
    MatchAllowed: {
      keySchema: {
        matchEntity: "bytes32",
        account: "address",
      },
      valueSchema: "bool",
    },
    /**
     * Whether a match has finished.
     */
    MatchFinished: {
      valueSchema: "bool",
    },
    MatchMapCopyProgress: "uint256",
    /**
     * Time when match Level copying is completed.
     */
    MatchReady: "uint256",
    /**
     * The ordered ranks of each player in the match.
     */
    MatchRanking: {
      valueSchema: "bytes32[]",
    },
    /**
     * The rewards for each place (1st, 2nd etc...) of match players.
     */
    MatchReward: {
      keySchema: {
        entity: "bytes32",
        rank: "uint256",
      },
      valueSchema: {
        token: "bytes32",
        value: "uint256",
      },
    },
    LatestMatch: {
      keySchema: {},
      valueSchema: "uint32",
    },
    /**
     * Used in map creation to mark the center of the map.
     */
    MapCenter: {
      valueSchema: "bool",
    },
    /**
     * Marks an entity as able to move.
     * The value is how many units there are able to move.
     * Represented in thousands.
     * i.e. 1000 = 1 unit.
     */
    Movable: {
      valueSchema: "int32",
    },
    /**
     * Given to terrain to determine how much it costs to move onto it.
     * Used in conjunction with Movable during path calculation.
     */
    MoveDifficulty: {
      valueSchema: "int32",
    },
    /**
     * Stores players chosen names.
     */
    Name: {
      valueSchema: "string",
    },
    /**
     * HEAVILY used to determine ownership chains.
     * i.e. Player -> Unit
     */
    OwnedBy: {
      valueSchema: "bytes32",
    },
    /**
     * Index for finding a player in a given Match.
     */
    MatchPlayer: {
      keySchema: {
        matchId: "uint32",
        playerAddress: "address",
      },
      valueSchema: {
        playerEntity: "bytes32",
      },
    },
    /**
     * Marks a player address as a player.
     * Value is an incrementing integer.
     */
    Player: {
      valueSchema: "uint32",
    },
    /**
     * Used in the lobby system to determine if a player is ready.
     */
    PlayerReady: {
      valueSchema: "uint256",
    },
    /**
     * The position of an entity.
     */
    Position: {
      valueSchema: {
        x: "int32",
        y: "int32",
        z: "int32",
      },
    },
    /**
     * Stores the table IDs a template is composed of.
     */
    TemplateTables: "bytes32[]",
    /**
     * Stores the content of each record in a template.
     */
    TemplateContent: {
      dataStruct: false,
      keySchema: {
        templateId: "bytes32",
        tableId: "ResourceId",
      },
      valueSchema: {
        encodedLengths: "PackedCounter",
        staticData: "bytes",
        dynamicData: "bytes",
      },
    },
    /**
     * Stores the template for each index in a level.
     */
    LevelTemplates: "bytes32[]",
    /**
     * Stores the indices of Level entities with a given `templateId`.
     */
    LevelTemplatesIndex: {
      keySchema: {
        levelId: "bytes32",
        templateId: "bytes32",
      },
      valueSchema: "uint256[]",
    },
    /**
     * Stores the table IDs a level entity is composed of.
     */
    LevelTables: {
      keySchema: {
        levelId: "bytes32",
        index: "uint256",
      },
      valueSchema: "bytes32[]",
    },
    /**
     * Stores the content of each record in a level entity.
     */
    LevelContent: {
      dataStruct: false,
      keySchema: {
        levelId: "bytes32",
        index: "uint256",
        tableId: "ResourceId",
      },
      valueSchema: {
        encodedLengths: "PackedCounter",
        staticData: "bytes",
        dynamicData: "bytes",
      },
    },
    /**
     * Stores the indices of Level entities with the given component values.
     */
    LevelContentIndex: {
      keySchema: {
        levelId: "bytes32",
        tableId: "ResourceId",
        encodedLengths: "PackedCounter",
        staticDataHash: "bytes32",
        dynamicDataHash: "bytes32",
      },
      valueSchema: "uint256[]",
    },
    /**
     * Whether a template is "virtual", meaning it is not instantiated during Level copying.
     */
    VirtualLevelTemplates: "bool",
    /**
     * The range at which an entity can engage in combat.
     */
    Range: {
      valueSchema: {
        min: "int32",
        max: "int32",
      },
    },
    /**
     * Set during Player registration to reserve a specific SpawnPoint in a level for a player entity.
     */
    SpawnReservedBy: {
      keySchema: { matchEntity: "bytes32", index: "uint256" },
      valueSchema: "bytes32",
    },
    /**
     * Makes an entity able to spawn a resource.
     * Value is the prototype ID of the resource.
     */
    ResourceSpawn: {
      valueSchema: "bytes32",
    },
    /**
     * Given to a Player entity when they spawn into a match.
     */
    Spawned: {
      valueSchema: "bool",
    },
    /**
     * Marks an entity as a Spawn Point.
     * Players can use it to enter a match.
     */
    SpawnPoint: {
      valueSchema: "bool",
    },
    /**
     * Stamina is the base resource that everything in the game uses to take actions.
     * It is lazily calculated whenever an entity takes an action.
     * Stamina is regenerated every turn.
     * Used in conjunction with LastAction to lazily calculate Stamina regen.
     */
    Stamina: {
      valueSchema: {
        current: "int32",
        max: "int32",
        regeneration: "int32",
      },
    },
    /**
     * How much Stamina an entity can regenerate in its lifetime.
     */
    StamRegenCap: {
      valueSchema: {
        totalRegenerated: "int32",
        cap: "int32",
      },
    },
    /**
     * Used to mark something as an Structure.
     * NOTE: Only use this to determine if something is an Structure contract-side.
     * Specific Structure Types are only used client-side to deteremine rendering.
     */
    StructureType: {
      valueSchema: "StructureTypes",
    },
    /**
     * Used to mark something as Terrain.
     * NOTE: Only use this to determine if something is Terrain contract-side.
     * Specific Terrain Types are only used client-side to deteremine rendering.
     */
    TerrainType: {
      offchainOnly: true,
      valueSchema: "TerrainTypes",
    },
    /**
     * Used to mark something as a Unit.
     * NOTE: Only use this to determine if something is a Unit contract-side.
     * Specific Unit Types are only used client-side to deteremine rendering.
     */
    UnitType: {
      valueSchema: "UnitTypes",
    },
    /**
     * The metadata for each token.
     */
    TokenMetadata: {
      valueSchema: {
        decimals: "uint8",
        name: "string",
        emoji: "string",
      },
    },
    /**
     * The supply for each token.
     */
    TokenSupply: {
      valueSchema: "uint256",
    },
    /**
     * The allowance of each entity for a given token.
     */
    TokenAllowance: {
      keySchema: {
        token: "bytes32",
        account: "bytes32",
        spender: "bytes32",
      },
      valueSchema: "uint256",
    },
    /**
     * The number of tokens owned by each entity for a given token.
     */
    TokenBalance: {
      keySchema: {
        token: "bytes32",
        entity: "bytes32",
      },
      valueSchema: "uint256",
    },
    /**
     * Whethere this entity blocks the movement of other entities.
     */
    Untraversable: {
      valueSchema: "bool",
    },
    Tier: "uint32",
  },
  modules: [
    {
      name: "UniqueEntityModule",
      root: true,
    },

    // KeysWithValueModule
    {
      name: "KeysWithValueModule",
      root: true,
      args: [resolveTableId("Position")],
    },
    {
      name: "KeysWithValueModule",
      root: true,
      args: [resolveTableId("Chargee")],
    },
    {
      name: "KeysWithValueModule",
      root: true,
      args: [resolveTableId("Match")],
    },
    {
      name: "KeysWithValueModule",
      root: true,
      args: [resolveTableId("Name")],
    },

    // KeysInTableModule
    {
      name: "KeysInTableModule",
      root: true,
      args: [resolveTableId("MatchSky")],
    },
  ],
});

const settlementTemplate = {
  StructureType: { value: 1 },
  Untraversable: { value: true },
  Capturable: { value: true },
  Combat: {
    health: 200_000,
    maxHealth: 200_000,
    armor: 10_000,
    strength: 0,
    structureStrength: 0,
    counterStrength: 0,
  },
  Factory: {
    prototypeIds: [
      stringToHex("Swordsman", { size: 32 }),
      stringToHex("Rider", { size: 32 }),
      stringToHex("Knight", { size: 32 }),
      stringToHex("Archer", { size: 32 }),
      stringToHex("Pikeman", { size: 32 }),
    ],
    staminaCosts: [500, 700, 900, 1100, 1600],
  },
} satisfies TemplatesConfig<typeof config>["Settlement"];

const templates: TemplatesConfig<typeof config> = {
  Swordsman: {
    Stamina: { current: 0, max: 1_000, regeneration: 1_000 },
    Combat: {
      health: 100_000,
      maxHealth: 100_000,
      armor: 10_000,
      strength: 40_000,
      structureStrength: 35_000,
      counterStrength: 70,
    },
    StaminaOnKill: { value: 50 },
    Range: { min: 0, max: 1 },
    Movable: { value: 4_000 },
    Untraversable: { value: true },
    Tier: { value: 3 },
    Capturer: { value: true },
    UnitType: { value: 1 },
  },
  Pikeman: {
    Stamina: { current: 0, max: 1_000, regeneration: 1_000 },
    Combat: {
      health: 200_000,
      maxHealth: 200_000,
      armor: 20_000,
      strength: 80_000,
      structureStrength: 70_000,
      counterStrength: 70,
    },
    StaminaOnKill: { value: 150 },
    Range: { min: 0, max: 1 },
    Movable: { value: 3_000 },
    Untraversable: { value: true },
    Tier: { value: 3 },
    Capturer: { value: true },
    UnitType: { value: 2 },
  },
  Golem: {
    Stamina: { current: 0, max: 1_000, regeneration: 1_000 },
    Combat: {
      health: 300_000,
      maxHealth: 300_000,
      armor: 20_000,
      strength: 70_000,
      structureStrength: 60_000,
      counterStrength: 70,
    },
    StaminaOnKill: { value: 250 },
    Range: { min: 0, max: 1 },
    Movable: { value: 4_000 },
    Untraversable: { value: true },
    Tier: { value: 3 },
    Capturer: { value: true },
    UnitType: { value: 3 },
  },
  GodUnit: {
    Stamina: { current: 0, max: 10_000, regeneration: 10_000 },
    Combat: {
      health: 1_000_000,
      maxHealth: 1_000_000,
      armor: 20_000,
      strength: 300_000,
      structureStrength: 300_000,
      counterStrength: 100,
    },
    StaminaOnKill: { value: 250 },
    Range: { min: 0, max: 1 },
    Movable: { value: 6_000 },
    Untraversable: { value: true },
    Tier: { value: 3 },
    Capturer: { value: true },
    UnitType: { value: 3 },
  },
  Rider: {
    Stamina: { current: 0, max: 1_000, regeneration: 1_000 },
    Combat: {
      health: 120_000,
      maxHealth: 120_000,
      armor: 0,
      strength: 30_000,
      structureStrength: 70_000,
      counterStrength: 50,
    },
    StaminaOnKill: { value: 100 },
    Range: { min: 0, max: 1 },
    Movable: { value: 5_000 },
    Untraversable: { value: true },
    Tier: { value: 3 },
    Capturer: { value: true },
    UnitType: { value: 4 },
  },
  Knight: {
    Stamina: { current: 0, max: 1000, regeneration: 1000 },
    Combat: {
      health: 150_000,
      maxHealth: 150_000,
      armor: 10_000,
      strength: 60_000,
      structureStrength: 50_000,
      counterStrength: 70,
    },
    StaminaOnKill: { value: 100 },
    Range: { min: 0, max: 1 },
    Movable: { value: 5_000 },
    Untraversable: { value: true },
    Tier: { value: 3 },
    Capturer: { value: true },
    UnitType: { value: 5 },
  },
  Dragon: {
    Stamina: { current: 0, max: 5_000, regeneration: 2_000 },
    Combat: {
      health: 100_000,
      maxHealth: 100_000,
      armor: 0,
      strength: 36_000,
      structureStrength: 36_000,
      counterStrength: 60,
    },
    Range: { min: 0, max: 1 },
    Movable: { value: 6_000 },
    Untraversable: { value: true },
    Tier: { value: 3 },
    Capturer: { value: true },
    UnitType: { value: 6 },
  },
  Archer: {
    Stamina: { current: 0, max: 1000, regeneration: 1000 },
    Combat: {
      health: 80_000,
      maxHealth: 80_000,
      armor: 0,
      strength: 60_000,
      structureStrength: 45_000,
      counterStrength: 0,
    },
    StaminaOnKill: { value: 100 },
    Range: { min: 2, max: 3 },
    Movable: { value: 3_000 },
    Untraversable: { value: true },
    Capturer: { value: true },
    Tier: { value: 3 },
    UnitType: { value: 7 },
  },
  Catapult: {
    Stamina: { current: 0, max: 1000, regeneration: 1000 },
    Combat: {
      health: 95_000,
      maxHealth: 95_000,
      armor: 3_000,
      strength: 72_000,
      structureStrength: 40_000,
      counterStrength: 0,
    },
    StaminaOnKill: { value: 200 },
    Capturer: { value: true },
    Range: { min: 2, max: 3 },
    Movable: { value: 2_500 },
    Untraversable: { value: true },
    Tier: { value: 3 },
    UnitType: { value: 8 },
  },
  Wizard: {
    Stamina: { current: 0, max: 5000, regeneration: 2000 },
    Combat: {
      health: 100000,
      maxHealth: 100000,
      armor: 0,
      strength: 28_000,
      structureStrength: 28_000,
      counterStrength: 25,
    },
    Range: { min: 2, max: 3 },
    Movable: { value: 4000 },
    Untraversable: { value: true },
    Tier: { value: 3 },
    UnitType: { value: 9 },
  },
  Grass: {
    TerrainType: { value: 1 },
    MoveDifficulty: { value: 1_000 },
  },
  Mountain: {
    TerrainType: { value: 2 },
    MoveDifficulty: { value: 2_000 },
    ArmorModifier: { value: 20_000 },
  },
  Water: {
    TerrainType: { value: 4 },
    Untraversable: { value: true },
  },
  Forest: {
    TerrainType: { value: 5 },
    MoveDifficulty: { value: 1_500 },
    ArmorModifier: { value: 10_000 },
  },
  StoneWall: {
    TerrainType: { value: 6 },
    Untraversable: { value: true },
  },
  LavaGround: {
    TerrainType: { value: 7 },
    MoveDifficulty: { value: 1000 },
  },
  Lava: {
    TerrainType: { value: 8 },
    Untraversable: { value: true },
  },
  LavaMountain: {
    TerrainType: { value: 9 },
    MoveDifficulty: { value: 2000 },
  },
  LavaForest: {
    TerrainType: { value: 10 },
    MoveDifficulty: { value: 1500 },
  },
  RockWall: {
    TerrainType: { value: 11 },
    Untraversable: { value: true },
  },

  MapCenterMarker: {
    MapCenter: { value: true },
  },

  // Structures
  Settlement: settlementTemplate,
  SpawnSettlement: {
    ...settlementTemplate,
    Charger: { value: 200 },
    ChargeCap: { cap: 6_400, totalCharged: 0 },
    StructureType: { value: 2 },
    SpawnPoint: { value: true },
    Combat: {
      health: 300_000,
      maxHealth: 300_000,
      armor: 20_000,
      strength: 0,
      structureStrength: 0,
      counterStrength: 0,
    },
    StaminaOnKill: { value: 500 },
    // Overriding the value on `settlementTemplate`
    Capturable: { value: false },
  },
  GoldMine: {
    StructureType: { value: 10 },
    Charger: { value: 100 },
    ChargeCap: { cap: 2_000, totalCharged: 0 },
    Capturable: { value: true },
    Untraversable: { value: true },
    Combat: {
      health: 100_000,
      maxHealth: 100_000,
      armor: 10_000,
      strength: 0,
      structureStrength: 0,
      counterStrength: 0,
    },
  },
  WoodenWall: {
    StructureType: { value: 9 },
    Combat: { health: 150_000, maxHealth: 150_000, armor: 0, strength: 0, structureStrength: 0, counterStrength: 0 },
    Untraversable: { value: true },
  },
};

const levels: LevelsConfig<typeof config> = {
  debug,
};

export default {
  ...config,
  templates,
  levels,
};
