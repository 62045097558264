import { defineSystem, Has } from "@latticexyz/recs";
import { Animations } from "../../phaserConstants";
import { PhaserLayer, RenderDepth } from "../../types";

export function createCaptureAnimationSystem(layer: PhaserLayer) {
  const {
    parentLayers: {
      local: {
        components: { Capturer },
        api: { getOwnerColor },
      },
      network: {
        network: { world },
      },
    },
    api: { getEntityPixelCoord, setOriginCenter },
    scenes: {
      Main: { phaserScene },
    },
  } = layer;

  defineSystem(world, [Has(Capturer)], ({ entity }) => {
    const { x, y } = getEntityPixelCoord(entity);
    const gameObject = phaserScene.add.sprite(x, y, "");
    const ownerColorName = getOwnerColor(entity).name;

    gameObject.setPosition(x, y - 20);
    gameObject.play(`${Animations.Capture}-${ownerColorName}`);
    gameObject.setDepth(RenderDepth.UI1);
    setOriginCenter(gameObject);

    gameObject.once("animationcomplete", () => {
      gameObject.destroy();
    });
  });
}
