import { Entity, getComponentValue, hasComponent } from "@latticexyz/recs";
import { useAmalgema } from "../../store";
import { Body } from "../ui/Theme/SkyStrife/Typography";
import { twMerge } from "tailwind-merge";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { DisplayNameWithLink } from "./CreatedBy";
import { useState } from "react";
import { MouseTooltip } from "./MouseTooltip";
import { encodeEntity } from "@latticexyz/store-sync/recs";
import { Hex } from "viem";
import { useComponentValue } from "@latticexyz/react";

export function MatchPlayers({ matchEntity }: { matchEntity: Entity }) {
  const {
    components: { MatchConfig, Recipient, SpawnReservedBy },
    utils: { getLevelSpawns },
  } = useAmalgema();

  const [showTooltip, setShowTooltip] = useState(false);

  const config = useComponentValue(MatchConfig, matchEntity);

  const spawns = config ? getLevelSpawns(config.levelId) : [];

  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className="flex flex-row w-full group"
    >
      {spawns.map((index, i) => {
        const spawnReserved = hasComponent(
          SpawnReservedBy,
          encodeEntity(SpawnReservedBy.metadata.keySchema, { matchEntity: matchEntity as Hex, index })
        );

        return (
          <div
            key={`spawn-${i}`}
            className={twMerge(
              "h-[24px] w-full border border-ss-white",
              spawnReserved ? "bg-ss-blue" : "bg-ss-bg-0",
              i === 0 ? "rounded-l-md" : i === spawns.length - 1 ? "rounded-r-md" : ""
            )}
          >
            {spawnReserved && (
              <Body className="w-full h-full flex flex-col items-center justify-around text-ss-text-default">
                P{i + 1}
              </Body>
            )}
          </div>
        );
      })}

      {showTooltip && (
        <MouseTooltip showTooltip={showTooltip}>
          <Card
            style={{
              boxShadow:
                "2px 2px 0px 0px #181710, 2px 2px 8px 0px rgba(24, 23, 16, 0.12), 2px 2px 16px 0px rgba(24, 23, 16, 0.08)",
            }}
            className="w-[180px]"
          >
            {spawns.map((index, i) => {
              const player = getComponentValue(
                SpawnReservedBy,
                encodeEntity(SpawnReservedBy.metadata.keySchema, { matchEntity: matchEntity as Hex, index })
              );
              const recipient = player && getComponentValue(Recipient, player.value as Entity);

              return (
                <div key={`spawn-${i}`} className="flex justify-between">
                  <div>P{i + 1}</div>
                  <div>{recipient ? <DisplayNameWithLink entity={recipient.value as Entity} /> : "empty"}</div>
                </div>
              );
            })}
          </Card>
        </MouseTooltip>
      )}
    </div>
  );
}
