import { Caption, OverlineSmall } from "../ui/Theme/SkyStrife/Typography";
import { useAmalgema } from "../../store";
import { useEntityQuery } from "@latticexyz/react";
import { Has, Not, getComponentValueStrict } from "@latticexyz/recs";
import { MatchCard } from "./MatchCard";

export function LiveMatches() {
  const {
    components: { MatchConfig, MatchReady, Match, MatchFinished },
    utils: { matchIsLive },
  } = useAmalgema();

  const matches = useEntityQuery([Has(Match), Has(MatchConfig), Has(MatchReady), Not(MatchFinished)]);

  const oneHour = 60n * 60n;
  const liveLobbies = matches.filter(
    (m) =>
      matchIsLive(m) &&
      getComponentValueStrict(MatchConfig, m)?.startTime + oneHour > BigInt(Math.floor(Date.now() / 1000))
  );

  return (
    <div>
      <OverlineSmall className="mb-4 uppercase">Live Islands ({liveLobbies.length})</OverlineSmall>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {liveLobbies.length === 0 && (
          <div className="w-full h-[200px] border border-ss-stroke rounded flex flex-col justify-around bg-ss-bg-0 col-span-3">
            <Caption className="text-center">No live islands</Caption>
          </div>
        )}
        {liveLobbies.map((i) => {
          return (
            <div key={i}>
              <MatchCard matchEntity={i} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
