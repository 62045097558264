import { world } from "./world";
import mudConfig from "contracts/mud.config";
import IWorldAbi from "contracts/out/IWorld.sol/IWorld.abi.json";
import { ContractWrite, createBurnerAccount, getContract, transportObserver, resourceToHex } from "@latticexyz/common";
import { encodeEntity, singletonEntity, syncToRecs } from "@latticexyz/store-sync/recs";
import { Entity } from "@latticexyz/recs";
import { map, filter, Subject, share } from "rxjs";
import { useStore } from "../store";
import { toAccount } from "viem/accounts";
import { NetworkConfig } from "./utils";
import { createClock } from "./createClock";
import {
  createPublicClient,
  fallback,
  webSocket,
  http,
  createWalletClient,
  Hex,
  ClientConfig,
  custom,
  pad,
} from "viem";
import { WindowProvider, configureChains, createConfig } from "wagmi";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { publicProvider } from "wagmi/providers/public";
import { createWaitForTransaction } from "./waitForTransaction";

type TableName = keyof (typeof mudConfig)["tables"];

const EXCLUDE_TABLES: TableName[] = [
  "Position",
  "MoveDifficulty",
  "TerrainType",
  "ArmorModifier",
  "Untraversable",
  "LastAction",
  "StaminaOnKill",
  "UnitType",
  "Movable",
  "Capturer",
  "Range",
  "Tier",
  "Combat",
  "StructureType",
  "Capturable",
  "KillCount",
  "Stamina",
  "ChargeCap",
  "Charger",
  "Chargee",
  "ChargedByStart",
  "Factory",
  "CombatResult",
  "MatchPlayer",
];

export const addressToEntityID = (address: Hex) => pad(address).toLowerCase() as Entity;

export type SetupNetworkResult = Awaited<ReturnType<typeof setupNetwork>>;

const tableIds = Object.keys(mudConfig.tables)
  .filter((name) => !EXCLUDE_TABLES.includes(name as TableName))
  .map((name) => resourceToHex({ type: "table", namespace: mudConfig.namespace, name }));

export async function setupNetwork(networkConfig: NetworkConfig) {
  const clientOptions = {
    chain: networkConfig.chain,
    transport: transportObserver(fallback([webSocket(), http()], { retryCount: 0 })),
    pollingInterval: 1000,
  } as const satisfies ClientConfig;

  const publicClient = createPublicClient(clientOptions);

  const matchId = networkConfig.match;

  const { components, latestBlock$, storedBlockLogs$ } = await syncToRecs({
    world,
    config: mudConfig,
    address: networkConfig.worldAddress as Hex,
    publicClient,
    indexerUrl: networkConfig.indexerUrl,
    startBlock: networkConfig.initialBlockNumber > 0n ? BigInt(networkConfig.initialBlockNumber) : undefined,
    tableIds: matchId === -1 ? tableIds : undefined,
  });
  const clock = createClock(networkConfig.clock);
  world.registerDisposer(() => clock.dispose());

  const txReceiptClient = createPublicClient({
    ...clientOptions,
    transport: http(),
  });

  const waitForTransaction = createWaitForTransaction({
    storedBlockLogs$,
    client: txReceiptClient,
  });

  latestBlock$
    .pipe(
      map((block) => Number(block.timestamp) * 1000), // Map to timestamp in ms
      filter((blockTimestamp) => blockTimestamp !== clock.lastUpdateTime), // Ignore if the clock was already refreshed with this block
      filter((blockTimestamp) => blockTimestamp !== clock.currentTime) // Ignore if the current local timestamp is correct
    )
    .subscribe(clock.update); // Update the local clock

  const burnerAccount = createBurnerAccount(networkConfig.privateKey as Hex);
  const walletClient = createWalletClient({
    ...clientOptions,
    account: burnerAccount,
  });

  const write$ = new Subject<ContractWrite>();
  const worldContract = getContract({
    address: networkConfig.worldAddress as Hex,
    abi: IWorldAbi,
    publicClient,
    walletClient,
    onWrite: (write) => {
      write$.next(write);
      write.result.then((tx) => {
        const { transactions } = useStore.getState();
        useStore.setState({ transactions: [...transactions, tx] });
      });
    },
  });

  const initialiseWallet = async (address: Hex | undefined) => {
    if (!networkConfig.useBurner) {
      if (address) {
        if (window.ethereum && window.ethereum.providers && window.ethereum.providers.length > 1) {
          const metamaskProvider = window.ethereum.providers.find((provider: WindowProvider) => provider.isMetaMask);
          if (metamaskProvider) window.ethereum = metamaskProvider;
        }

        if (!window.ethereum) {
          console.error("No ethereum provider found during wallet initialisation.");
          return;
        }

        const externalWalletClient = createWalletClient({
          chain: networkConfig.chain,
          transport: custom(window.ethereum),
          account: toAccount(address),
        });
        const externalWorldContract = getContract({
          address: networkConfig.worldAddress as Hex,
          abi: IWorldAbi,
          publicClient,
          walletClient: externalWalletClient,
          onWrite: (write) => {
            write.result.then((tx) => {
              const { transactions } = useStore.getState();
              useStore.setState({ transactions: [...transactions, tx] });
            });
          },
        });

        useStore.setState({ externalWalletClient, externalWorldContract });
      } else {
        useStore.setState({ externalWalletClient: null, externalWorldContract: null });
      }
    }
  };

  // If flag is set, use the burner key as the "External" wallet
  if (networkConfig.useBurner) {
    const externalWalletClient = walletClient;
    const externalWorldContract = worldContract;

    useStore.setState({ externalWalletClient, externalWorldContract });
  }

  const { chain } = publicClient;
  const chainCopy = { ...chain };
  if (chainCopy.fees) {
    delete chainCopy.fees; // Delete the BigInt property as it cannot be serialised by Wagmi
  }
  const { chains } = configureChains([chainCopy], [publicProvider()]);

  const connectors = connectorsForWallets([
    {
      groupName: "Recommended",
      wallets: [metaMaskWallet({ projectId: "YOUR_PROJECT_ID", chains })],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return {
    world,
    components,
    singletonEntity,
    playerEntity: encodeEntity({ address: "address" }, { address: walletClient.account.address }),
    publicClient,
    walletClient,
    waitForTransaction,
    worldContract,
    networkConfig,
    match: networkConfig.match,
    clock,
    initialiseWallet,
    write$: write$.asObservable().pipe(share()),
    latestBlock$,
    storedBlockLogs$,
    chains,
    wagmiConfig,
  };
}
