import { useState } from "react";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Caption } from "../ui/Theme/SkyStrife/Typography";
import { useAmalgema } from "../../store";
import { getComponentValueStrict } from "@latticexyz/recs";
import {
  Hex,
  encodeFunctionData,
  getAbiItem,
  getFunctionSelector,
  isAddress,
  keccak256,
  stringToHex,
  toHex,
} from "viem";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { resourceToHex } from "@latticexyz/common";
import { CrossIcon } from "../ui/Theme/CrossIcon";
import { MATCH_URL } from "../links";
import { randomBytes } from "ethers/lib/utils";

const LEVEL_ID = stringToHex("FourPlayer", { size: 32 });

export function SummonPrivateMatch({
  insufficientBalance,
  close,
}: {
  insufficientBalance: boolean;
  close: () => void;
}) {
  const {
    components: { LatestMatch, LevelTemplates },
    network: { singletonEntity, walletClient },
    utils: { getLevelSpawns },
    externalWalletClient,
    externalWorldContract,
    executeSystemWithExternalWallet,
  } = useAmalgema();

  const [summonPending, setSummonPending] = useState(false);
  const [newAccount, setNewAccount] = useState("");
  const [accounts, setAccounts] = useState<Hex[]>(
    externalWalletClient && externalWalletClient.account ? [externalWalletClient.account.address] : []
  );

  const spawnsInLevel = getLevelSpawns(LEVEL_ID).map(
    (entity) => decodeEntity(LevelTemplates.metadata.keySchema, entity).index
  );
  const createMatch = async () => {
    if (summonPending || !externalWorldContract) return;

    setSummonPending(true);
    const { abi } = externalWorldContract;
    const preimage = toHex(randomBytes(32));
    const matchEntity = keccak256(preimage);

    await executeSystemWithExternalWallet({
      systemCall: "batchCall",
      args: [
        [
          [
            // create a match with an access list
            {
              systemId: resourceToHex({ type: "system", namespace: "", name: "MatchSystem" }),
              callData: encodeFunctionData({
                abi,
                functionName: "createMatchWithPreimage",
                args: [
                  LEVEL_ID,
                  resourceToHex({ type: "system", namespace: "", name: "AllowListSystem" }),
                  getFunctionSelector(getAbiItem({ abi, name: "isMember" })),
                  preimage,
                ],
              }),
            },
            // set the access list
            {
              systemId: resourceToHex({ type: "system", namespace: "", name: "AllowListSystem" }),
              callData: encodeFunctionData({
                abi,
                functionName: "setMembers",
                args: [matchEntity, accounts],
              }),
            },
          ],
        ],
      ],
    });

    setSummonPending(false);
    close();
  };

  const createAndRegisterMatch = async () => {
    if (!externalWorldContract) return;

    const { abi } = externalWorldContract;
    const preimage = toHex(randomBytes(32));
    const matchEntity = keccak256(preimage);

    await executeSystemWithExternalWallet({
      systemCall: "batchCall",
      args: [
        [
          [
            // create a match with an access list
            {
              systemId: resourceToHex({ type: "system", namespace: "", name: "MatchSystem" }),
              callData: encodeFunctionData({
                abi,
                functionName: "createMatchWithPreimage",
                args: [
                  LEVEL_ID,
                  resourceToHex({ type: "system", namespace: "", name: "AllowListSystem" }),
                  getFunctionSelector(getAbiItem({ abi, name: "isMember" })),
                  preimage,
                ],
              }),
            },
            // set the access list
            {
              systemId: resourceToHex({ type: "system", namespace: "", name: "AllowListSystem" }),
              callData: encodeFunctionData({
                abi,
                functionName: "setMembers",
                args: [matchEntity, accounts],
              }),
            },
            // register the match creator
            {
              systemId: resourceToHex({ type: "system", namespace: "", name: "PlayerRegisterSystem" }),
              callData: encodeFunctionData({
                abi,
                functionName: "register",
                args: [matchEntity, spawnsInLevel[0], walletClient.account.address],
              }),
            },
          ],
        ],
      ],
    });

    const matchId = getComponentValueStrict(LatestMatch, singletonEntity).value;

    const matchParams = new URLSearchParams(window.location.search);
    matchParams.append("match", matchId.toString());
    window.open(`${MATCH_URL}?${matchParams}`);
  };

  const notEnoughAddresses = accounts.length < spawnsInLevel.length;
  const createDisabled = insufficientBalance || notEnoughAddresses;
  let createButtonText = null;
  if (summonPending) createButtonText = "Loading...";
  if (insufficientBalance) createButtonText = "Insuffient Balance";
  if (notEnoughAddresses) createButtonText = "Too few addresses";

  let addAddressDisabledMessage = null;
  if (accounts.includes(newAccount as Hex)) addAddressDisabledMessage = "Duplicate Address";
  if (newAccount.length > 0 && !isAddress(newAccount)) addAddressDisabledMessage = "Invalid Address";

  return (
    <div>
      <div className="flex flex-row justify-between">
        <input
          className="grow rounded px-4 py-2"
          type="text"
          placeholder="Enter wallet address"
          value={newAccount}
          onChange={(e) => {
            setNewAccount(e.target.value);
          }}
        />

        <div className="w-3" />

        <Button
          buttonType="primary"
          disabled={!isAddress(newAccount) || accounts.includes(newAccount)}
          onClick={() => {
            if (isAddress(newAccount) && !accounts.includes(newAccount)) {
              setAccounts([...accounts, newAccount as Hex]);
              setNewAccount("");
            }
          }}
        >
          add
        </Button>
      </div>

      {addAddressDisabledMessage && (
        <div className="mt-2">
          <Caption className="text-red-500">{addAddressDisabledMessage}</Caption>
        </div>
      )}

      <Caption>Private matches require at least {spawnsInLevel.length} wallet addresses on the access list.</Caption>

      <div className="mt-4 mb-4">
        {accounts.map((account) => (
          <div key={account} className="flex flex-row justify-between w-full bg-gray-100 border-2 px-4 py-2">
            <span>{account}</span>
            <button
              onClick={() => {
                setAccounts(accounts.filter((a) => a !== account));
              }}
            >
              <CrossIcon />
            </button>
          </div>
        ))}
      </div>

      <div className="flex flex-row">
        <Button disabled={createDisabled} buttonType={"tertiary"} onClick={createMatch} className="h-fit grow">
          {createButtonText || "Create"}
        </Button>

        <div className="w-3" />

        <Button
          disabled={createDisabled}
          buttonType={"secondary"}
          onClick={createAndRegisterMatch}
          className="h-fit grow"
        >
          {createButtonText || "Create and join"}
        </Button>
      </div>
    </div>
  );
}
