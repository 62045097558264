import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Body, Heading, OverlineLarge } from "../ui/Theme/SkyStrife/Typography";
import { useAmalgema } from "../../store";
import { Entity, Has, HasValue, getComponentValue } from "@latticexyz/recs";
import { resourceToHex } from "@latticexyz/common";
import { MatchRewardsFooter } from "./MatchRewardsFooter";
import { Hex } from "viem";
import { CreatedBy } from "./CreatedBy";
import { MatchPlayers } from "./MatchPlayers";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { ListIcon } from "../ui/Theme/ListIcon";
import { useCallback, useMemo, useState } from "react";
import { useRef } from "react";
import useOnClickOutside from "../ui/hooks/useOnClickOutside";
import { addressToEntityID } from "../../mud/setupNetwork";

const RESOURCE_SELECTOR = resourceToHex({ type: "table", namespace: "", name: "AllowListSystem" });

function AllowList({ matchEntity }: { matchEntity: Entity }) {
  const {
    components: { MatchAllowed },
  } = useAmalgema();

  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const allowed = useEntityQuery([Has(MatchAllowed)])
    .map((entity) => {
      return decodeEntity(MatchAllowed.metadata.keySchema, entity);
    })
    .filter(({ matchEntity: myMatchEntity }) => {
      return myMatchEntity === matchEntity;
    });

  useOnClickOutside(ref, () => setVisible(false));

  return (
    <div>
      <Button buttonType="tertiary" onClick={() => setVisible(true)}>
        <ListIcon />
      </Button>
      {visible && (
        <div
          style={{
            background: "rgba(24, 23, 16, 0.65)",
            zIndex: 100,
          }}
          className="fixed top-0 left-0 w-screen h-screen flex flex-col justify-around"
        >
          <div ref={ref} className="mx-auto">
            <Card primary className="bg-ss-bg-1 flex flex-col justify-center p-8 w-[624px]">
              <div className="flex justify-between items-center">
                <OverlineLarge>Access List</OverlineLarge>

                <Button buttonType={"tertiary"} onClick={() => setVisible(false)} className="h-fit py-1">
                  Close
                </Button>
              </div>

              <div className="h-6" />

              <Body>This is a private match. Only wallet addresses on the access list can join.</Body>

              <div className="h-6" />

              {allowed.length > 0
                ? allowed.map(({ account }) => <div key={account}>{account}</div>)
                : "This access list is empty."}
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

export function OpenMatch({ matchEntity }: { matchEntity: Entity }) {
  const {
    components: { Match, MatchAccessControl, MatchAllowed, MatchConfig, Player, OwnedBy, Name },
    network: { walletClient, publicClient },
    utils: { getAvailableLevelSpawns },
    externalWalletClient,
    externalWorldContract,
    executeSystemWithExternalWallet,
  } = useAmalgema();

  const [pendingTx, setPendingTx] = useState(false);

  const matchId = useComponentValue(Match, matchEntity)?.value;
  const matchConfig = useComponentValue(MatchConfig, matchEntity);
  const matchAccessControl = useComponentValue(MatchAccessControl, matchEntity);

  const hasAllowList = matchAccessControl && matchAccessControl.resourceSelector === RESOURCE_SELECTOR;

  const isAllowed =
    useEntityQuery([Has(MatchAllowed)])
      .map((entity) => decodeEntity(MatchAllowed.metadata.keySchema, entity))
      .some(
        ({ matchEntity: entity, account }) =>
          externalWalletClient &&
          externalWalletClient.account &&
          entity === matchEntity &&
          account === externalWalletClient.account.address
      ) || !hasAllowList;

  const allPlayersInMatch = useEntityQuery([Has(Player), HasValue(Match, { value: matchId })]);

  const currentPlayerInMatch = Boolean(
    allPlayersInMatch.find((p) => {
      const ownedBy = getComponentValue(OwnedBy, p)?.value as Hex;
      return ownedBy === addressToEntityID(walletClient.account.address);
    })
  );

  const matchParams = useMemo(() => {
    const params = new URLSearchParams();
    params.append("match", matchId?.toString() ?? "");
    return params;
  }, [matchId]);

  const spectateParams = new URLSearchParams(matchParams);
  spectateParams.append("spectate", "true");

  const joinMatch = useCallback(async () => {
    if (!matchConfig) return;
    if (!matchId) return;
    if (!externalWorldContract) return;
    if (!externalWalletClient) return;
    if (externalWalletClient.account?.address === undefined) return;

    // Try add chain for external wallets, in case they are on the wrong network
    if (externalWalletClient.account && externalWalletClient.transport.type === "custom") {
      await externalWalletClient.addChain({ chain: publicClient.chain });
      await externalWalletClient.switchChain({ id: publicClient.chain.id });
    }

    const spawns = getAvailableLevelSpawns(matchConfig.levelId, matchEntity as Hex);

    const spawn = spawns[Math.floor(Math.random() * spawns.length)];

    const playerAddress = externalWalletClient.account.address;
    const name = getComponentValue(Name, addressToEntityID(playerAddress))?.value;

    const systemPromise = executeSystemWithExternalWallet({
      systemCall: "registerWithName",
      args: [[matchEntity as Hex, spawn, name ?? playerAddress.slice(0, 8), walletClient.account.address]],
    });
    setPendingTx(true);

    try {
      await systemPromise;
    } catch (e) {
      console.error(e);
    } finally {
      setPendingTx(false);
    }

    window.open(`/match?${matchParams}`);
  }, [
    Name,
    executeSystemWithExternalWallet,
    externalWalletClient,
    externalWorldContract,
    getAvailableLevelSpawns,
    matchConfig,
    matchEntity,
    matchId,
    matchParams,
    publicClient.chain,
    walletClient.account.address,
  ]);

  if (!matchId || !matchConfig) return <></>;

  return (
    <Card primary>
      <div className="flex flex-row justify-between items-center">
        <span className="flex flex-row items-center">
          <div className="text-lg">{hasAllowList ? "🔒" : "📜"}</div>
          <div className="w-2" />
          <Heading className="normal-case">Joinable island</Heading>
        </span>
        <Body className="text-ss-text-x-light">#{matchId}</Body>
      </div>

      {matchConfig && <CreatedBy createdBy={matchConfig.createdBy as Hex} />}

      <div className="h-3"></div>

      <MatchPlayers matchEntity={matchEntity} />

      <div className="h-6"></div>

      <div className="flex flex-row">
        {currentPlayerInMatch ? (
          <a href={`/match?${matchParams}`} target="_blank" rel="noreferrer" className="pointer w-full">
            <Button buttonType={"secondary"} className="w-full">
              open match
            </Button>
          </a>
        ) : (
          <Button disabled={pendingTx || !isAllowed} buttonType={"secondary"} className="w-full" onClick={joinMatch}>
            {pendingTx ? "Joining..." : "Join"}
          </Button>
        )}

        {hasAllowList && (
          <>
            <div className="w-3" />
            <AllowList matchEntity={matchEntity} />
          </>
        )}
      </div>

      <div className="mb-3"></div>

      <a href={`/match?${spectateParams}`} target="_blank" rel="noopener noreferrer">
        <Button buttonType={"tertiary"} className="w-full">
          Spectate
        </Button>
      </a>

      <div className="mb-4" />

      <MatchRewardsFooter matchId={matchId} />
    </Card>
  );
}
