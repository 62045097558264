import { useEffect } from "react";
import { drip } from "../../mud/faucet";
import { useAmalgema } from "../../store";

export function ExternalWalletDrip() {
  const {
    network: {
      publicClient,
      networkConfig: { faucetServiceUrl },
    },
    externalWalletClient,
  } = useAmalgema();

  useEffect(() => {
    if (!externalWalletClient || !faucetServiceUrl || !externalWalletClient.account) return;

    drip(externalWalletClient.account.address, faucetServiceUrl, publicClient);
  }, [externalWalletClient, faucetServiceUrl, publicClient]);

  return <></>;
}
