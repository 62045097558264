import { Has, HasValue, getComponentValue } from "@latticexyz/recs";
import { useAmalgema } from "../../store";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Body, Heading } from "../ui/Theme/SkyStrife/Typography";
import { useEntityQuery } from "@latticexyz/react";
import { CreatedBy } from "./CreatedBy";
import { Hex } from "viem";
import { MatchPlayers } from "./MatchPlayers";
import { addressToEntityID } from "../../mud/setupNetwork";

export function LiveMatch({ matchId }: { matchId: number }) {
  const {
    components: { Match, MatchConfig, Player, OwnedBy },
    network: { walletClient },
  } = useAmalgema();

  const match = useEntityQuery([HasValue(Match, { value: matchId }), Has(MatchConfig)])[0];
  const matchConfig = getComponentValue(MatchConfig, match);

  const allPlayersInMatch = useEntityQuery([Has(Player), HasValue(Match, { value: matchId })]);

  const currentPlayerInMatch = Boolean(
    allPlayersInMatch.find((p) => {
      const ownedBy = getComponentValue(OwnedBy, p)?.value as Hex;
      return ownedBy === addressToEntityID(walletClient.account.address);
    })
  );

  if (!match) return <></>;

  return (
    <Card primary={false}>
      <div className="flex flex-row justify-between items-center">
        <Heading className="normal-case">Island</Heading>
        <Body className="text-ss-text-x-light">#{matchId}</Body>
      </div>

      {matchConfig?.createdBy && <CreatedBy createdBy={matchConfig.createdBy as Hex} />}

      <div className="h-3"></div>

      <MatchPlayers matchEntity={match} />

      <div className="mb-4"></div>

      {!currentPlayerInMatch && (
        <a href={`/match?match=${matchId}&spectate=true`} target="_blank" rel="noopener noreferrer">
          <Button buttonType={"tertiary"} className="w-full">
            Spectate
          </Button>
        </a>
      )}

      {currentPlayerInMatch && (
        <a href={`/match?match=${matchId}`} target="_blank" rel="noopener noreferrer">
          <Button buttonType={"tertiary"} className="w-full">
            Play
          </Button>
        </a>
      )}
    </Card>
  );
}
