export const ListIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M8.91675 6.75H20.9167M8.91675 12H20.9167M8.91675 17.25H20.9167M4.41675 6.75H4.42375V6.758H4.41675V6.75ZM4.79175 6.75C4.79175 6.84946 4.75224 6.94484 4.68191 7.01516C4.61159 7.08549 4.5162 7.125 4.41675 7.125C4.31729 7.125 4.22191 7.08549 4.15158 7.01516C4.08126 6.94484 4.04175 6.84946 4.04175 6.75C4.04175 6.65054 4.08126 6.55516 4.15158 6.48484C4.22191 6.41451 4.31729 6.375 4.41675 6.375C4.5162 6.375 4.61159 6.41451 4.68191 6.48484C4.75224 6.55516 4.79175 6.65054 4.79175 6.75ZM4.41675 12H4.42375V12.008H4.41675V12ZM4.79175 12C4.79175 12.0995 4.75224 12.1948 4.68191 12.2652C4.61159 12.3355 4.5162 12.375 4.41675 12.375C4.31729 12.375 4.22191 12.3355 4.15158 12.2652C4.08126 12.1948 4.04175 12.0995 4.04175 12C4.04175 11.9005 4.08126 11.8052 4.15158 11.7348C4.22191 11.6645 4.31729 11.625 4.41675 11.625C4.5162 11.625 4.61159 11.6645 4.68191 11.7348C4.75224 11.8052 4.79175 11.9005 4.79175 12ZM4.41675 17.25H4.42375V17.258H4.41675V17.25ZM4.79175 17.25C4.79175 17.3495 4.75224 17.4448 4.68191 17.5152C4.61159 17.5855 4.5162 17.625 4.41675 17.625C4.31729 17.625 4.22191 17.5855 4.15158 17.5152C4.08126 17.4448 4.04175 17.3495 4.04175 17.25C4.04175 17.1505 4.08126 17.0552 4.15158 16.9848C4.22191 16.9145 4.31729 16.875 4.41675 16.875C4.5162 16.875 4.61159 16.9145 4.68191 16.9848C4.75224 17.0552 4.79175 17.1505 4.79175 17.25Z"
      stroke="#5D5D4C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
