import { useState } from "react";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { useAmalgema } from "../../store";
import { getComponentValueStrict } from "@latticexyz/recs";
import { encodeFunctionData, getAbiItem, getFunctionSelector, keccak256, stringToHex, toHex } from "viem";
import { resourceToHex } from "@latticexyz/common";
import { MATCH_URL } from "../links";
import { randomBytes } from "ethers/lib/utils";

const LEVEL_ID = stringToHex("FourPlayer", { size: 32 });

export function SummonPublicMatch({ insufficientBalance, close }: { insufficientBalance: boolean; close: () => void }) {
  const {
    network: {
      components: { LatestMatch },
      singletonEntity,
      walletClient,
    },
    utils: { getLevelSpawns },
    externalWorldContract,
    executeSystemWithExternalWallet,
  } = useAmalgema();

  const [summonPending, setSummonPending] = useState(false);

  const createMatch = async () => {
    if (summonPending || !externalWorldContract) return;

    setSummonPending(true);
    const { abi } = externalWorldContract;

    await executeSystemWithExternalWallet({
      systemCall: "createMatch",
      args: [
        [
          LEVEL_ID,
          resourceToHex({ type: "system", namespace: "", name: "AllowAnySystem" }),
          getFunctionSelector(getAbiItem({ abi, name: "isAllowed" })),
        ],
      ],
    });

    setSummonPending(false);
    close();
  };

  const createAndRegisterMatch = async () => {
    if (summonPending || !externalWorldContract) return;

    setSummonPending(true);
    const { abi } = externalWorldContract;
    const preimage = toHex(randomBytes(32));
    const matchEntity = keccak256(preimage);

    const spawnsInLevel = getLevelSpawns(LEVEL_ID);

    await executeSystemWithExternalWallet({
      systemCall: "batchCall",
      args: [
        [
          [
            // create a match
            {
              systemId: resourceToHex({ type: "system", namespace: "", name: "MatchSystem" }),
              callData: encodeFunctionData({
                abi,
                functionName: "createMatchWithPreimage",
                args: [
                  LEVEL_ID,
                  resourceToHex({ type: "system", namespace: "", name: "AllowAnySystem" }),
                  getFunctionSelector(getAbiItem({ abi, name: "isAllowed" })),
                  preimage,
                ],
              }),
            },
            // register the match creator
            {
              systemId: resourceToHex({ type: "system", namespace: "", name: "PlayerRegisterSystem" }),
              callData: encodeFunctionData({
                abi,
                functionName: "register",
                args: [matchEntity, spawnsInLevel[0], walletClient.account.address],
              }),
            },
          ],
        ],
      ],
    });

    setSummonPending(false);

    // We don't have a way to get a return value of a tx in the client right now
    // Here we rely on the LatestMatch table which is updated every time a match is created
    // It's possible that this is incorrect if many matches are being created at the exact same moment
    // Fingers crossed this works most of the time...
    const matchId = getComponentValueStrict(LatestMatch, singletonEntity).value;

    const matchParams = new URLSearchParams(window.location.search);
    matchParams.append("match", matchId.toString());
    window.open(`${MATCH_URL}?${matchParams}`);

    close();
  };

  return (
    <>
      <div className="flex flex-row">
        <Button disabled={insufficientBalance} buttonType={"tertiary"} onClick={createMatch} className="h-fit grow">
          {summonPending ? "Loading..." : insufficientBalance ? "Insufficient Balance" : "Create"}
        </Button>

        <div className="w-3" />

        <Button
          disabled={insufficientBalance}
          buttonType={"secondary"}
          onClick={createAndRegisterMatch}
          className="h-fit grow"
        >
          {summonPending ? "Loading..." : insufficientBalance ? "Insufficient Balance" : "Create and join"}
        </Button>
      </div>
    </>
  );
}
