import { Link } from "../ui/Theme/SkyStrife/Typography";
import { Hex, decodeFunctionData } from "viem";
import { PendingIcon } from "../ui/Theme/PendingIcon";
import { useAmalgema } from "../../store";
import { usePromiseValue } from "../../usePromiseValue";
import { ConfirmedIcon } from "../ui/Theme/ConfirmedIcon";
import { CrossIcon } from "../ui/Theme/CrossIcon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getTransaction } from "./utils/getTransaction";
import { getTransactionReceipt } from "./utils/getTransactionReceipt";
import { ExclaimIcon } from "../ui/Theme/ExclaimIcon";
import { Entity, getComponentValueStrict } from "@latticexyz/recs";

const AUTOMATIC_DISMISS_TIME = 10_000;

const ConfirmedTransaction = ({
  startDismiss,
  timeUntilDismiss,
  functionData,
  blockExplorer,
  hash,
  onClick,
}: {
  startDismiss: boolean;
  timeUntilDismiss: number;
  functionData: any;
  blockExplorer: string | undefined;
  hash: Hex;
  onClick: () => void;
}) => {
  const {
    network: {
      components: { Match },
    },
  } = useAmalgema();

  const functionDataToMessageConfirmed = (functionData: ReturnType<typeof decodeFunctionData>) => {
    if (functionData.functionName === "copyMap") {
      const matchId = functionData.args ? getComponentValueStrict(Match, functionData.args[0] as Entity).value : "?";

      return `Island #${matchId} summoned!`;
    } else if (functionData.functionName === "createMatch" || functionData.functionName === "batchCall") {
      return `Created match!`;
    } else if (functionData.functionName === "setName") {
      return `Set name!`;
    } else if (functionData.functionName === "registerDelegation") {
      return `Registered delegation!`;
    } else if (functionData.functionName === "registerWithName") {
      return `Registered for match!`;
    }

    return functionData?.functionName;
  };

  return (
    <div
      style={{
        border: "1px solid #4FC530",
        boxShadow: "2px 2px 0px 0px rgba(24, 23, 16, 0.90)",
      }}
      className="bg-[#E3FFDB] rounded-lg overflow-hidden"
    >
      {startDismiss && (
        <div
          className="bg-[#4FC530] h-1 rounded-lg"
          style={{
            width: `${(timeUntilDismiss / AUTOMATIC_DISMISS_TIME) * 100}%`,
          }}
        />
      )}
      <div className="flex px-4 py-3">
        <ConfirmedIcon />
        <div className="ml-2 grow">
          <div className="text-[#2E2D27]">{functionData ? functionDataToMessageConfirmed(functionData) : "?"}</div>
          {blockExplorer && (
            <Link className="text-ss-text-light hover:text-[#2E2D27] underline" href={`${blockExplorer}/tx/${hash}`}>
              View on block explorer
            </Link>
          )}
        </div>
        <div onClick={onClick}>
          <CrossIcon />
        </div>
      </div>
    </div>
  );
};

const FailedTransaction = ({
  startDismiss,
  timeUntilDismiss,
  functionData,
  blockExplorer,
  hash,
  onClick,
}: {
  startDismiss: boolean;
  timeUntilDismiss: number;
  functionData: any;
  blockExplorer: string | undefined;
  hash: Hex;
  onClick: () => void;
}) => {
  const {
    network: {
      components: { Match },
    },
  } = useAmalgema();

  const functionDataToMessageFailed = (functionData: ReturnType<typeof decodeFunctionData>) => {
    if (functionData.functionName === "copyMap") {
      const matchId = functionData.args ? getComponentValueStrict(Match, functionData.args[0] as Entity).value : "?";

      return `Summoning Island #${matchId} failed!`;
    } else if (functionData.functionName === "createMatch" || functionData.functionName === "batchCall") {
      return `Creating match failed!`;
    } else if (functionData.functionName === "setName") {
      return `Set name failed!`;
    } else if (functionData.functionName === "registerDelegation") {
      return `Registering delegation failed!`;
    } else if (functionData.functionName === "registerWithName") {
      return `Registering for match failed!`;
    }

    return functionData?.functionName;
  };

  return (
    <div
      style={{
        border: "1px solid #D62F15",
        boxShadow: "2px 2px 0px 0px rgba(24, 23, 16, 0.90)",
      }}
      className="bg-[#FFDCD6] rounded-lg overflow-hidden"
    >
      {startDismiss && (
        <div
          className="bg-[#FF8888] h-1 rounded-lg"
          style={{
            width: `${(timeUntilDismiss / AUTOMATIC_DISMISS_TIME) * 100}%`,
          }}
        />
      )}
      <div className="flex px-4 py-3">
        <ExclaimIcon />
        <div className="ml-2 grow">
          <div className="text-[#2E2D27]">{functionData ? functionDataToMessageFailed(functionData) : "?"}</div>
          {blockExplorer && (
            <Link className="text-ss-text-light hover:text-[#2E2D27] underline" href={`${blockExplorer}/tx/${hash}`}>
              View on block explorer
            </Link>
          )}
        </div>
        <div onClick={onClick}>
          <CrossIcon />
        </div>
      </div>
    </div>
  );
};

const PendingTransaction = ({
  functionData,
  blockExplorer,
  hash,
}: {
  functionData: any;
  blockExplorer: string | undefined;
  hash: Hex;
}) => {
  const {
    network: {
      components: { Match },
    },
  } = useAmalgema();

  const functionDataToMessagePending = (functionData: ReturnType<typeof decodeFunctionData>) => {
    if (functionData.functionName === "copyMap") {
      const matchId = functionData.args ? getComponentValueStrict(Match, functionData.args[0] as Entity).value : "?";

      return `Summoning Island ${matchId}`;
    } else if (functionData.functionName === "createMatch" || functionData.functionName === "batchCall") {
      return `Creating match`;
    } else if (functionData.functionName === "setName") {
      return `Setting name`;
    } else if (functionData.functionName === "registerDelegation") {
      return `Registering delegation`;
    } else if (functionData.functionName === "registerWithName") {
      return `Registering for match`;
    }

    return functionData?.functionName;
  };

  return (
    <div
      style={{
        border: "1px solid #EBA433",
        boxShadow: "2px 2px 0px 0px rgba(24, 23, 16, 0.90)",
      }}
      className="bg-[#FFE8C2] rounded-lg"
    >
      {/* spacer that mimics the dismissal bar */}
      <div className="h-[4px]" />
      <div className="flex px-4 py-3 items-center">
        <div className="w-1" />
        <PendingIcon />
        <div className="w-1" />
        <div className="ml-2 grow">
          <div>{functionData ? functionDataToMessagePending(functionData) : "?"}</div>
          <div>{blockExplorer && <Link href={`${blockExplorer}/tx/${hash}`}>View on block explorer</Link>}</div>
        </div>
      </div>
    </div>
  );
};

// Inspired by MUD dev tools
const TransactionSummary = ({
  hash,
  dismissedTransactions,
  setDismissedTransactions,
}: {
  hash: Hex;
  dismissedTransactions: Array<Hex>;
  setDismissedTransactions: Dispatch<SetStateAction<Hex[]>>;
}) => {
  const {
    network: { publicClient, worldContract },
  } = useAmalgema();

  const [timeUntilDismiss, setTimeUntilDismiss] = useState(AUTOMATIC_DISMISS_TIME);
  const [startDismiss, setStartDismiss] = useState(false);

  const transactionPromise = getTransaction(publicClient, hash);
  const transactionReceiptPromise = getTransactionReceipt(publicClient, hash);
  const transaction = usePromiseValue(transactionPromise);
  const transactionReceipt = usePromiseValue(transactionReceiptPromise);

  const functionData = transaction && decodeFunctionData({ abi: worldContract.abi, data: transaction.input });

  const blockExplorer = publicClient.chain.blockExplorers?.default.url;

  useEffect(() => {
    if (!startDismiss) return;

    const interval = setInterval(() => {
      setTimeUntilDismiss((timeUntilDismiss) => timeUntilDismiss - 10);
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [startDismiss]);

  useEffect(() => {
    if (timeUntilDismiss <= 0) {
      setDismissedTransactions([...dismissedTransactions, hash]);
    }
  });

  useEffect(() => {
    if (transactionReceipt && transactionReceipt.blockNumber) setStartDismiss(true);
  }, [transactionReceipt]);

  // If block number is null, tx is pending
  return (
    <div className="">
      {transactionReceipt && transactionReceipt.blockNumber ? (
        transactionReceipt.status === "success" ? (
          <ConfirmedTransaction
            startDismiss={startDismiss}
            timeUntilDismiss={timeUntilDismiss}
            functionData={functionData}
            blockExplorer={blockExplorer}
            hash={hash}
            onClick={() => {
              setDismissedTransactions([...dismissedTransactions, hash]);
            }}
          />
        ) : (
          <FailedTransaction
            startDismiss={startDismiss}
            timeUntilDismiss={timeUntilDismiss}
            functionData={functionData}
            blockExplorer={blockExplorer}
            hash={hash}
            onClick={() => {
              setDismissedTransactions([...dismissedTransactions, hash]);
            }}
          />
        )
      ) : (
        <PendingTransaction functionData={functionData} blockExplorer={blockExplorer} hash={hash} />
      )}
    </div>
  );
};

function DisplayTransactions({ transactions }: { transactions: Hex[] }) {
  const [dismissedTransactions, setDismissedTransactions] = useState<Array<Hex>>([]);

  const visibleTransactions = transactions.filter((value) => !dismissedTransactions.includes(value));

  return (
    visibleTransactions.length > 0 && (
      <div className="absolute left-1/2 -translate-x-1/2 my-8 w-96">
        {visibleTransactions.slice(0, 3).map((hash, i) =>
          i === 0 ? (
            <div key={hash} className="flex justify-center w-full">
              <div className="absolute top-8 w-80 z-20">
                <TransactionSummary
                  hash={hash}
                  dismissedTransactions={dismissedTransactions}
                  setDismissedTransactions={setDismissedTransactions}
                />
              </div>
            </div>
          ) : i === 1 ? (
            <div key={hash} className="flex justify-center w-full">
              <div className="absolute top-4 w-72 z-10">
                <TransactionSummary
                  hash={hash}
                  dismissedTransactions={dismissedTransactions}
                  setDismissedTransactions={setDismissedTransactions}
                />
              </div>
            </div>
          ) : (
            <div key={hash} className="flex justify-center w-full">
              <div className="absolute top-0 w-64 z-0">
                <TransactionSummary
                  hash={hash}
                  dismissedTransactions={dismissedTransactions}
                  setDismissedTransactions={setDismissedTransactions}
                />
              </div>
            </div>
          )
        )}
      </div>
    )
  );
}

export function Transactions() {
  const { transactions } = useAmalgema();

  return <DisplayTransactions transactions={transactions} />;
}
