import { Entity } from "@latticexyz/recs";
import { useAmalgema } from "../../store";
import { IconButton } from "../ui/Theme/SkyStrife/IconButton";
import { Discord } from "../ui/Theme/SkyStrife/Icons/Discord";
import { Body, Heading, Link, OverlineSmall } from "../ui/Theme/SkyStrife/Typography";
import { useTokenBalances } from "./hooks/useTokenBalances";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Twitter } from "../ui/Theme/SkyStrife/Icons/Twitter";
import { DISCORD_URL, FEEDBACK_URL, HOW_TO_PLAY_URL, TWITTER_URL } from "../links";
import { CurrentProfile } from "./Profile";
import { formatUnits, parseEther } from "viem";
import { addressToEntityID } from "../../mud/setupNetwork";
import { Tutorial } from "../ui/Theme/SkyStrife/Icons/Tutorial";
import { Mana } from "../ui/Theme/SkyStrife/Mana";

export function InventorySidebar() {
  const { externalWalletClient, externalWorldContract } = useAmalgema();

  const tokenBalances = useTokenBalances(
    externalWalletClient && externalWalletClient.account
      ? addressToEntityID(externalWalletClient.account.address)
      : ("0" as Entity)
  );

  return (
    <div className="bg-ss-bg-1 border-l border-ss-stroke flex flex-col p-8 pt-4">
      <CurrentProfile />

      <div className="h-6" />

      <div className="flex justify-between">
        <OverlineSmall>my resources</OverlineSmall>
        {tokenBalances.map(({ emoji, value, decimals }, i) => (
          <Body key={`${i}-balance`} className="text-ss-text-highlight flex items-center">
            <div
              className="flex"
              onClick={() => {
                if (externalWalletClient && externalWorldContract) {
                  const { address } = externalWorldContract;
                  externalWalletClient.watchAsset({
                    type: "ERC20",
                    options: {
                      address,
                      decimals,
                      symbol: emoji,
                    },
                  });
                }
              }}
            >
              {emoji} {formatUnits(value, decimals)}
            </div>
          </Body>
        ))}
        {tokenBalances.length === 0 && <Body className="text-ss-text-highlight flex items-center">🔮 0</Body>}
      </div>

      <div className="flex justify-between">
        <OverlineSmall>match creation cost</OverlineSmall>
        <Mana amount={BigInt(parseEther("150"))} />
      </div>

      <div className="h-8" />

      <div className="bg-[#F8F2DD] rounded border border-ss-stroke w-full flex flex-col p-4">
        <Body className="text-ss-text-default">Sky Strife is in development.</Body>
        <div className="h-2" />
        <Body>We are currently running a two-week deployment to push Sky Strife to its limits.</Body>
        <div className="h-2" />
        <Body>
          You will likely encounter bugs or instability at some point. If you do,{" "}
          <Link href={FEEDBACK_URL}>please let us know.</Link>
        </Body>
      </div>

      <div className="h-8" />

      <Heading>How to Play</Heading>
      <Body>Learn how to play and win Sky Strife.</Body>
      <div className="h-4" />

      <div className="bg-ss-bg-0 rounded border border-ss-stroke w-full flex flex-col p-4">
        <ol className="list-decimal px-4">
          <li>
            <Body className="text-ss-text-default">
              Four players spawn with a castle, hero, and 1,000 gold to summon units with.
            </Body>
          </li>
          <div className="h-2" />

          <li>
            <Body className="text-ss-text-default">Capture mines to generate more gold.</Body>
          </li>
          <div className="h-2" />

          <li>
            <Body className="text-ss-text-default">
              Fight your opponent’s armies and destroy their castle to defeat them.
            </Body>
          </li>
          <div className="h-2" />

          <li>
            <Body className="text-ss-text-default">Be the last castle standing to win.</Body>
          </li>
          <div className="h-2" />

          <li>
            <Body className="text-ss-text-default">
              The top three players earn 🔮 which can be used to create more matches.
            </Body>
          </li>
        </ol>
      </div>

      <div className="grow" />

      <div className="flex">
        <a href={FEEDBACK_URL} className="grow" target="_blank" rel="noreferrer">
          <Button className="w-full" buttonType="tertiary">
            Submit Feedback
          </Button>
        </a>

        <div className="w-8" />

        <a href={DISCORD_URL} target="_blank" rel="noreferrer">
          <IconButton>
            <Discord />
          </IconButton>
        </a>

        <div className="w-2" />

        <a href={TWITTER_URL} target="_blank" rel="noreferrer">
          <IconButton>
            <Twitter />
          </IconButton>
        </a>

        <div className="w-2" />

        <a href={HOW_TO_PLAY_URL} target="_blank" rel="noreferrer">
          <IconButton>
            <Tutorial />
          </IconButton>
        </a>
      </div>
    </div>
  );
}
