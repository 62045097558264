import { useMUD } from "../../store";
import { Entity, Has, getComponentValue, getComponentValueStrict } from "@latticexyz/recs";
import { useEntityQuery } from "@latticexyz/react";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { isDefined } from "@latticexyz/common/utils";
import { formatEther } from "viem";

export const Balances = ({ player }: { player: Entity }) => {
  const {
    networkLayer: {
      network: {
        components: { TokenBalance, TokenMetadata },
      },
    },
  } = useMUD();

  const balances = useEntityQuery([Has(TokenBalance)])
    .map((key) => {
      const { token, entity } = decodeEntity(TokenBalance.metadata.keySchema, key);
      if (entity !== player) return;

      const metadata = getComponentValue(TokenMetadata, token as Entity);
      if (!metadata) return;
      const { name, emoji } = metadata;

      const { value } = getComponentValueStrict(TokenBalance, key);

      return { token, name, emoji, value };
    })
    .filter(isDefined);

  return (
    <div className="p-4">
      <div className="text-4xl p-2">Amalgema</div>
      <div className="text-3xl">My tokens</div>
      <table className="w-full text-lg text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xl text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th>Token</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {balances.length > 0 ? (
            balances.map((balance) => (
              <tr key={balance.token}>
                <th>
                  {balance.name} {balance.emoji}
                </th>
                <th>{formatEther(balance.value)}</th>
              </tr>
            ))
          ) : (
            <tr className="p-2">
              <td>You have no tokens.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
