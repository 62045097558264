import { useState } from "react";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Mana } from "../ui/Theme/SkyStrife/Mana";
import { Caption } from "../ui/Theme/SkyStrife/Typography";
import { MouseTooltip } from "./MouseTooltip";
import { useMatchRewards } from "./hooks/useMatchRewards";

// from https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export function ordinalSuffix(i: number) {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

/**
 * Designed to be rendered at the bottom of a Card component.
 */
export function MatchRewardsFooter({ matchId }: { matchId: number }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const rewards = useMatchRewards(matchId);

  const totalReward = rewards.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0n);

  return (
    <div
      style={{
        marginLeft: "-24px",
        marginBottom: "-16px",
        width: "calc(100% + 48px)",
        borderRadius: "0 0 8px 8px",
      }}
      className="bg-ss-bg-0 border-t border-ss-stroke px-6 py-4"
    >
      <div className="flex flex-row justify-between items-center group">
        <Caption>Reward pool</Caption>
        <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          <Mana
            style={{
              borderBottom: "2px dotted #000",
              lineHeight: "20px",
            }}
            amount={totalReward}
          />
        </div>

        <MouseTooltip showTooltip={showTooltip}>
          <Card
            style={{
              boxShadow:
                "2px 2px 0px 0px #181710, 2px 2px 8px 0px rgba(24, 23, 16, 0.12), 2px 2px 16px 0px rgba(24, 23, 16, 0.08)",
            }}
          >
            {rewards
              .filter((reward) => reward.value > 0)
              .map((reward, i) => (
                <div key={i} className="flex flex-row justify-between items-center">
                  <Caption>{ordinalSuffix(i + 1)} place</Caption>
                  <Mana amount={reward.value} />
                </div>
              ))}
          </Card>
        </MouseTooltip>
      </div>
    </div>
  );
}
