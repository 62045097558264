import React, { useEffect, useMemo } from "react";
import { BootScreen } from "./BootScreen";
import { getComponentValue } from "@latticexyz/recs";
import { concat, map } from "rxjs";
import { useObservableValue } from "../../useObservableValue";
import { filterNullish } from "@latticexyz/utils";
import { NetworkLayer } from "../../layers/Network";
import { LoadingBar } from "./LoadingBar";
import { Button } from "./Theme/SkyStrife/Button";
import { Body } from "./Theme/SkyStrife/Typography";
import { SyncStep } from "@latticexyz/store-sync";

type Props = {
  networkLayer: NetworkLayer | null;
};

export const LoadingScreen = ({ networkLayer }: Props) => {
  const [hide, setHide] = React.useState(false);

  const loadingState = useObservableValue(
    useMemo(() => {
      if (!networkLayer) return;

      const {
        components: { SyncProgress },
        network: { singletonEntity },
      } = networkLayer;

      // use LoadingState.update$ as a trigger rather than a value
      // and concat with an initial value to trigger the first look up
      return concat([1], SyncProgress.update$).pipe(
        map(() => {
          const loadingState = getComponentValue(SyncProgress, singletonEntity);
          return loadingState ?? null;
        }),
        filterNullish()
      );
    }, [networkLayer]),
    {
      message: "Connecting",
      percentage: 0,
      step: SyncStep.INITIALIZE,
      latestBlockNumber: 0n,
      lastBlockNumberProcessed: 0n,
    }
  );

  const [ready, setReady] = React.useState(false);
  const [fakeProgress, setFakeProgress] = React.useState(0);

  useEffect(() => {
    if (loadingState.step !== SyncStep.LIVE) return;

    const waitTime = import.meta.env.PROD ? 8_000 : 100;
    setTimeout(() => {
      setReady(true);
    }, waitTime);

    const numberOfIntervals = 100;
    const interval = setInterval(() => {
      setFakeProgress((prev) => prev + 100 / numberOfIntervals);
    }, waitTime / numberOfIntervals);

    return () => {
      clearInterval(interval);
    };
  }, [loadingState.step]);

  if (hide) {
    return null;
  }

  return (
    <BootScreen initialOpacity={1}>
      {ready ? (
        <div className="flex flex-col items-center mt-8">
          <Body className="text-lg">Ready!</Body>
          <Button
            buttonType="primary"
            onClick={() => {
              setHide(true);
            }}
            className="mt-4 w-3/4"
          >
            Click to enter the game
          </Button>
        </div>
      ) : loadingState.step === SyncStep.LIVE ? (
        <Body className="flex flex-col items-center w-full mt-8">
          <span className="text-lg">
            {loadingState.percentage !== 100 ? loadingState.message : "Preparing game..."}
          </span>
          <span className="text-center">
            {loadingState.percentage !== 100 ? Math.floor(loadingState.percentage) : fakeProgress}%
          </span>
          <LoadingBar
            className="w-3/4 mt-2"
            percentage={loadingState.percentage !== 100 ? loadingState.percentage : fakeProgress}
          />
        </Body>
      ) : (
        <div className="flex flex-row w-full mt-8 justify-center items-center">
          <img height="64px" width="64px" src="/public/assets/dragoon_attack.gif" />
          <div className="w-4"></div>
          <Body className="text-center text-3xl text-ss-text-default">
            Fetching blockchain data… <br /> This may take a few minutes.
          </Body>
          <div className="w-4"></div>
          <img height="64px" width="64px" src="/public/assets/dragoon_attack.gif" />
        </div>
      )}
    </BootScreen>
  );
};
