import { Caption, OverlineSmall } from "../ui/Theme/SkyStrife/Typography";
import { useAmalgema } from "../../store";
import { useEntityQuery } from "@latticexyz/react";
import { Has, Not } from "@latticexyz/recs";
import { MatchCard } from "./MatchCard";

export function HistoricalMatches() {
  const {
    components: { MatchConfig, MatchReady, Match, MatchFinished },
  } = useAmalgema();

  const matches = useEntityQuery([Has(Match), Has(MatchConfig), Has(MatchReady), Has(MatchFinished)]);

  return (
    <div>
      <OverlineSmall className="mb-4 uppercase">Past Islands ({matches.length})</OverlineSmall>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {matches.length === 0 && (
          <div className="w-full h-[200px] border border-ss-stroke rounded flex flex-col justify-around bg-ss-bg-0 col-span-3">
            <Caption className="text-center">No past islands</Caption>
          </div>
        )}
        {matches.map((i) => {
          return (
            <div key={i}>
              <MatchCard matchEntity={i} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
