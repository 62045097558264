import { HasValue, getComponentValue, hasComponent, runQuery, setComponent } from "@latticexyz/recs";
import { filter, map, merge } from "rxjs";
import { WorldCoord } from "../../../../../types";
import { worldCoordEq } from "../../../../../utils/coords";
import { PhaserLayer } from "../../types";
import { pixelToWorldCoord } from "../../utils";
import { InputUtils } from "./createInputSystem";

export function registerClicks(layer: PhaserLayer, { getSelectedEntity, getHighlightedEntity }: InputUtils) {
  const {
    parentLayers: {
      network: {
        components: { OwnedBy },
        utils: { isOwnedByCurrentPlayer },
      },
      headless: {
        components: { NextPosition },
        api: {
          canAttack,
          attack,
          calculateMovementPath,
          getMoveAndAttackPath,
          getAttackableEntities,
          getCurrentStamina,
        },
      },
      local: {
        api: { selectArea, resetSelection, move },
        components: { PotentialPath, LocalPosition },
        singletonEntity,
      },
    },
    api: {
      mapInteraction: { mapInteractionEnabled },
    },
    scenes: {
      Main: { input, maps },
    },
    components: { PreviousHoverHighlight },
  } = layer;

  const onClick = function (clickedPosition: WorldCoord) {
    const selectedEntity = getSelectedEntity();

    // If the player owns the select unit...
    if (selectedEntity && isOwnedByCurrentPlayer(selectedEntity)) {
      const highlightedEntity = getHighlightedEntity();
      const currentPosition = getComponentValue(LocalPosition, selectedEntity);
      if (!currentPosition) return;

      // If the player is hovering over an empty tile
      if (highlightedEntity == null) {
        if (getCurrentStamina(selectedEntity) < 1_000) {
          resetSelection();
          selectArea({ ...clickedPosition, width: 1, height: 1 });
          return;
        }

        const nextPosition = getComponentValue(NextPosition, selectedEntity);
        const nextPositionAtClickedPosition = [
          ...runQuery([
            HasValue(NextPosition, {
              x: clickedPosition.x,
              y: clickedPosition.y,
            }),
          ]),
        ][0];

        // Confirm movement location and send tx
        if (nextPosition && nextPosition.userCommittedToPosition && worldCoordEq(clickedPosition, nextPosition)) {
          move(selectedEntity, clickedPosition);
          resetSelection(false);
        } else if (!nextPosition && nextPositionAtClickedPosition) {
          /**
           * no-op
           * there is another unit planning to move to this position
           */
        }
        // If the player can move to the location, set the next position
        else if (
          (!nextPosition || !nextPosition.userCommittedToPosition) &&
          hasComponent(PotentialPath, selectedEntity) &&
          calculateMovementPath(LocalPosition, selectedEntity, currentPosition, clickedPosition).length > 0
        ) {
          setComponent(NextPosition, selectedEntity, {
            ...clickedPosition,
            userCommittedToPosition: true,
            intendedTarget: undefined,
          });

          const attackableEntities = getAttackableEntities(selectedEntity, clickedPosition);
          // If there are no attackable entities, move to the location
          if (attackableEntities && attackableEntities.length === 0) {
            move(selectedEntity, clickedPosition);
            resetSelection(false);
          }
        }
        // Clicked outside of interactable area, reset selection
        else {
          resetSelection();
          selectArea({ ...clickedPosition, width: 1, height: 1 });
        }
        // If the player is hovering over a unit
      } else {
        // first check if we can attack the unit A) without moving or B) after moving to the NextPosition coordinate
        if (canAttack(selectedEntity, highlightedEntity)) {
          const attackerOwner = getComponentValue(OwnedBy, selectedEntity);
          const defenderOwner = getComponentValue(OwnedBy, highlightedEntity);

          if (!attackerOwner || attackerOwner.value !== defenderOwner?.value) {
            const nextPosition = getComponentValue(NextPosition, selectedEntity);
            if (nextPosition && !worldCoordEq(nextPosition, currentPosition)) {
              move(selectedEntity, nextPosition, highlightedEntity);
              resetSelection(false);
            } else {
              attack(selectedEntity, highlightedEntity);
              resetSelection(false);
            }
          }
        } else {
          // Check if we can calculate a path to the unit
          const previouslyHoveredPosition = getComponentValue(PreviousHoverHighlight, singletonEntity);
          const selectedEntityPosition = getComponentValue(LocalPosition, selectedEntity);
          // if you last hovered over yourself, we assume you want to stay still and attack
          const moveAndAttackPath = !worldCoordEq(previouslyHoveredPosition, selectedEntityPosition)
            ? getMoveAndAttackPath(LocalPosition, selectedEntity, highlightedEntity, previouslyHoveredPosition)
            : [];

          const attackerOwner = getComponentValue(OwnedBy, selectedEntity);
          const defenderOwner = getComponentValue(OwnedBy, highlightedEntity);

          if (attackerOwner?.value !== defenderOwner?.value) {
            if (moveAndAttackPath.length > 0) {
              const moveDestination = moveAndAttackPath[moveAndAttackPath.length - 1];

              setComponent(NextPosition, selectedEntity, {
                ...moveDestination,
                userCommittedToPosition: true,
                intendedTarget: highlightedEntity,
              });
              move(selectedEntity, moveDestination, highlightedEntity);
              resetSelection(false);
            } else if (canAttack(selectedEntity, highlightedEntity)) {
              attack(selectedEntity, highlightedEntity);
              resetSelection(false);
            }
          } else {
            resetSelection();
            selectArea({ ...clickedPosition, width: 1, height: 1 });
          }
        }
      }
    } else {
      resetSelection();
      selectArea({ ...clickedPosition, width: 1, height: 1 });
    }
  };

  merge(input.click$, input.rightClick$)
    .pipe(
      filter((pointer) => pointer.event.target instanceof HTMLCanvasElement && mapInteractionEnabled()),
      filter((pointer) => pointer.button === 0), // left click only
      map((pointer) => ({ x: pointer.worldX, y: pointer.worldY })),
      map((pixel) => pixelToWorldCoord(maps.Main, pixel))
    )
    .subscribe((coord) => {
      onClick(coord);
    });
}
