import { defineEnterSystem, Has, HasValue, runQuery } from "@latticexyz/recs";
import { PhaserLayer } from "../../types";

export function createPlayerSpawnSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { SpawnPoint, OwnedBy, MatchFinished, Match },
        utils: { getPlayerEntity },
        network: { playerEntity: playerAddress, match },
      },
      local: {
        components: { MatchStarted },
        api: { resetSelection },
      },
    },
    api: { selectAndView },
  } = layer;

  defineEnterSystem(world, [Has(MatchStarted)], () => {
    const spawnSettlement = [
      ...runQuery([Has(SpawnPoint), HasValue(OwnedBy, { value: getPlayerEntity(playerAddress) })]),
    ][0];
    if (!spawnSettlement) return;

    selectAndView(spawnSettlement);
  });

  // When loading a match that is finished
  // we don't want the home settlement to be selected
  // and bleed through the end screen
  defineEnterSystem(world, [Has(MatchFinished), HasValue(Match, { value: match })], () => {
    resetSelection();
  });
}
