import { Has, HasValue } from "@latticexyz/recs";
import { useMUD } from "../../../store";
import { useComponentValue, useEntityQuery } from "@latticexyz/react";

export const useCurrentPlayer = () => {
  const {
    networkLayer: {
      components: { Name, Match, OwnedBy, Player },
      network: { playerEntity: playerAddress, match },
    },
    localLayer: {
      api: { getOwnerColor },
    },
  } = useMUD();

  const playerEntity = useEntityQuery([
    HasValue(OwnedBy, { value: playerAddress }),
    HasValue(Match, { value: match }),
    Has(Player),
  ])[0];

  const name = useComponentValue(Name, playerEntity)?.value;
  const playerColor = playerEntity
    ? getOwnerColor(playerEntity)
    : {
        name: "white",
        color: 0xffffff,
      };

  return {
    player: playerEntity,
    playerColor,
    name,
    match,
  };
};
