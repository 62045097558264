import { useAmalgema } from "../../store";
import { useEffect, useRef, useState } from "react";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Hex } from "viem";
import { OverlineLarge, OverlineSmall, Link, Heading, Caption } from "../ui/Theme/SkyStrife/Typography";
import { useComponentValue } from "@latticexyz/react";
import { addressToEntityID } from "../../mud/setupNetwork";
import { ConnectButton, useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount, useBalance } from "wagmi";
import "@rainbow-me/rainbowkit/styles.css";
import useOnClickOutside from "../ui/hooks/useOnClickOutside";
import { PromiseButton } from "../ui/hooks/PromiseButton";
import { AnalyticsConsentForm } from "../AnalyticsConsentForm";

export const formatAddress = (address: Hex) => address.slice(0, 6) + "..." + address.slice(-4);

const SettingsModal = ({ address, close }: { address: Hex; close: () => void }) => {
  const { network, externalWorldContract, executeSystemWithExternalWallet } = useAmalgema();
  const {
    publicClient,
    components: { Name },
  } = network;

  const name = useComponentValue(Name, addressToEntityID(address));
  const [newName, setNewName] = useState(name ? name.value : "");

  const blockExplorer = publicClient.chain.blockExplorers?.default.url;

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, close);

  return (
    <div
      style={{
        background: "rgba(24, 23, 16, 0.65)",
        zIndex: 100,
      }}
      className="fixed top-0 left-0 w-screen h-screen flex flex-col justify-around"
    >
      <div ref={ref} className="mx-auto">
        <Card primary className="bg-ss-bg-1 flex flex-col justify-center p-8 w-fit w-[624px]">
          <div className="flex justify-between items-center">
            <OverlineLarge>Account</OverlineLarge>

            <Button buttonType={"tertiary"} onClick={close} className="h-fit py-1">
              Close
            </Button>
          </div>

          <div className="h-8"></div>

          <div>
            <OverlineSmall>Connection</OverlineSmall>

            <ConnectButton />

            <div className="h-3" />

            <OverlineSmall>Wallet address</OverlineSmall>

            {blockExplorer ? (
              <Link
                href={`${blockExplorer}/address/${address}
            `}
              >
                {address}
              </Link>
            ) : (
              <span>{address}</span>
            )}
          </div>

          <div className="h-6"></div>

          <div>
            <OverlineSmall>Username</OverlineSmall>
            <div className="flex">
              <input
                className="bg-ss-bg-0 rounded border border-ss-stroke w-full px-3 py-2 shadow-ss-small"
                placeholder="Enter a username"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />

              <div className="w-8" />

              {externalWorldContract &&
                PromiseButton({
                  buttonType: "secondary",
                  children: "Confirm",
                  promise: () => executeSystemWithExternalWallet({ systemCall: "setName", args: [[newName]] }),
                })}
            </div>
          </div>

          <div className="h-6"></div>

          <AnalyticsConsentForm />
        </Card>
      </div>
    </div>
  );
};

const CurrentSettingsModal = ({ close }: { close: () => void }) => {
  const { externalWalletClient } = useAmalgema();

  return (
    externalWalletClient &&
    externalWalletClient.account && <SettingsModal address={externalWalletClient.account.address} close={close} />
  );
};

function Profile({ address }: { address: Hex }) {
  const {
    network: {
      components: { Name },
    },
  } = useAmalgema();

  const [visible, setVisible] = useState(false);

  const name = useComponentValue(Name, addressToEntityID(address));

  const { data } = useBalance({
    address,
    watch: true,
  });

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <Heading className="text-lg">{name ? name.value : formatAddress(address)}</Heading>
          <Caption className="text-sm text-ss-text-x-light">
            Balance: {data?.formatted} {data?.symbol}
          </Caption>
        </div>

        <Button buttonType={"tertiary"} onClick={() => setVisible(true)} className="h-fit">
          Settings
        </Button>
        {visible && <CurrentSettingsModal close={() => setVisible(false)} />}
      </div>
    </div>
  );
}

export function CurrentProfile() {
  const {
    externalWalletClient,
    network: { initialiseWallet },
  } = useAmalgema();

  const { address } = useAccount();
  const { openConnectModal } = useConnectModal();

  useEffect(() => {
    initialiseWallet(address);
  }, [address, initialiseWallet]);

  return externalWalletClient && externalWalletClient.account ? (
    <Profile address={externalWalletClient.account.address} />
  ) : (
    <Button buttonType={"secondary"} className="h-fit" onClick={openConnectModal}>
      Connect Wallet
    </Button>
  );
}
