import { Hex, PublicClient, parseEther } from "viem";
import { createFaucetService } from "@latticexyz/services/faucet";

export function drip(address: Hex, faucetServiceUrl: string, publicClient: PublicClient) {
  console.info("[Dev Faucet]: Player address -> ", address);

  const faucet = createFaucetService(faucetServiceUrl);

  const requestDrip = async () => {
    const balance = await publicClient.getBalance({ address });
    console.info(`[Dev Faucet]: Player balance -> ${balance}`);
    if (balance < parseEther("1")) {
      console.info("[Dev Faucet]: Balance is low, dripping funds to player");
      // Double drip
      await faucet.dripDev({ address });
      await faucet.dripDev({ address });
    }
  };

  requestDrip();
  // Request a drip every 20 seconds
  setInterval(requestDrip, 20000);
}
