import {
  defineRxSystem,
  defineSyncSystem,
  Entity,
  getComponentValue,
  Has,
  hasComponent,
  HasValue,
  Not,
  runQuery,
  setComponent,
} from "@latticexyz/recs";
import { isNeutralStructure } from "../../../../Headless/utils";
import { PhaserLayer } from "../../types";

export function createCalculateCombatResultSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { TerrainType, Combat, OwnedBy },
      },
      headless: {
        api: {
          canAttack,
          combat: { calculateCombatResult },
        },
      },
      local: {
        components: { LocalPosition, Selected },
        api: { canMoveToAndAttack },
      },
    },
    components: { HoverHighlight, IncomingDamage },
  } = layer;

  defineSyncSystem(
    world,
    [Has(Combat)],
    () => IncomingDamage,
    () => ({ value: 0 }),
    { runOnInit: true }
  );

  let previousAttacker: Entity | undefined;
  let previousDefender: Entity | undefined;
  defineRxSystem(world, HoverHighlight.update$, (update) => {
    const [hoverHighlight, previous] = update.value;
    if (hoverHighlight?.x === previous?.x && hoverHighlight?.y === previous?.y) return;
    if (!hoverHighlight) return;

    const attacker = [...runQuery([Has(Selected)])][0];

    const defender = [
      ...runQuery([HasValue(LocalPosition, { x: hoverHighlight.x, y: hoverHighlight.y }), Not(TerrainType)]),
    ][0];

    const attackerOwner = getComponentValue(OwnedBy, attacker)?.value;
    const defenderOwner = getComponentValue(OwnedBy, defender)?.value;

    if (previousAttacker) {
      setComponent(IncomingDamage, previousAttacker, { value: 0 });
      previousAttacker = undefined;
    }

    if (previousDefender) {
      setComponent(IncomingDamage, previousDefender, { value: 0 });
      previousDefender = undefined;
    }

    if (
      isNeutralStructure(layer.parentLayers.network, attacker) ||
      attackerOwner === defenderOwner ||
      attacker === defender ||
      !hasComponent(Combat, attacker) ||
      !hasComponent(Combat, defender) ||
      (!canAttack(attacker, defender) && !canMoveToAndAttack(attacker, defender))
    ) {
      return;
    }

    const combatResult = calculateCombatResult(layer.parentLayers.network, attacker, defender);

    setComponent(IncomingDamage, attacker, { value: combatResult.defenderDamage * 1000 });
    setComponent(IncomingDamage, defender, { value: combatResult.attackerDamage * 1000 });

    previousAttacker = attacker;
    previousDefender = defender;
  });
}
