import { useState } from "react";
import { useFilePicker } from "use-file-picker";
import { useMUD } from "../../../store";
import { bulkUploadMap, Level } from "./bulkUploadMap";
import { Button } from "../Theme/SkyStrife/Button";

export const MapUpload = () => {
  const [openFileSelector, fileData] = useFilePicker({
    accept: ".json",
  });
  const [sendingTxs, setSendingTxs] = useState(false);
  const [levelName, setLevelName] = useState("");

  const layers = useMUD();

  const { filesContent, loading } = fileData;

  const UploadFileButton = () => {
    return (
      <>
        {!sendingTxs && filesContent[0] !== undefined && (
          <Button
            buttonType={"primary"}
            onClick={async () => {
              setSendingTxs(true);
              try {
                await bulkUploadMap(layers.networkLayer, JSON.parse(filesContent[0].content) as Level, levelName);
              } finally {
                setSendingTxs(false);
              }
            }}
          >
            Upload!
          </Button>
        )}
      </>
    );
  };

  return (
    <div>
      <h1 className="self-start text-white text-3xl">Map Upload</h1>

      <Button buttonType={"primary"} onClick={openFileSelector}>
        Find Map File
      </Button>
      <input
        className="bg-[#1b1c20] text-white"
        type="text"
        placeholder="Level Name"
        onChange={(e) => setLevelName(e.target.value)}
      />
      {loading && <div>Loading...</div>}
      {filesContent && levelName.length > 0 && (
        <div>
          {filesContent.map((file) => (
            <div key={file.name}>
              <h3 className="text-white">{file.name}</h3>
            </div>
          ))}
        </div>
      )}

      <UploadFileButton />
    </div>
  );
};
