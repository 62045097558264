import { useEffect } from "react";
import { useStore } from "../store";
import { useNetworkLayer } from "./useNetworkLayer";
import { AmalgemaUIRoot } from "./amalgema-ui/AmalgemaUIRoot";
import { LoadingScreen } from "./amalgema-ui/LoadingScreen";
import { WagmiConfig, configureChains, createConfig, mainnet } from "wagmi";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { publicProvider } from "wagmi/providers/public";
import { Link } from "./ui/Theme/SkyStrife/Typography";
import { DISCORD_URL } from "./links";

// Default config setup
const { publicClient } = configureChains([mainnet], [publicProvider()]);
const wagmiConfig = createConfig({
  publicClient,
});

export const Amalgema = () => {
  const networkLayer = useNetworkLayer();

  useEffect(() => {
    if (networkLayer) {
      useStore.setState({ networkLayer });
      document.title = `Sky Strife - Main Menu`;
    }
  }, [networkLayer]);

  const MAINTENANCE_MODE = import.meta.env.PROD && true;

  return MAINTENANCE_MODE ? (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <div
        style={{
          background:
            "linear-gradient(152deg, rgba(244, 243, 241, 0.98) 0%, rgba(244, 243, 241, 0.88) 100%), url(/assets/ship-background.jpeg), lightgray -381.491px 0.145px / 126.42% 100% no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "right",
          zIndex: -2,
        }}
        className="fixed top-0 left-0 h-screen w-screen bg-cover"
      />
      <div className="text-4xl font-bold">The two-week playtest has ended.</div>
      <div className="h-3" />
      <div className="text-xl text-ss-text-light text-center">
        You can still play Sky Strife during our weekly playtest on Fridays at 10:30am EST.
        <br></br>
        Follow on{" "}
        <Link style={{ fontSize: "18px" }} href={DISCORD_URL}>
          Discord
        </Link>{" "}
        for more updates.
      </div>
    </div>
  ) : (
    <WagmiConfig config={networkLayer ? networkLayer.network.wagmiConfig : wagmiConfig}>
      <RainbowKitProvider chains={networkLayer ? networkLayer.network.chains : []}>
        <div>
          <LoadingScreen networkLayer={networkLayer} />

          <AmalgemaUIRoot />
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
