import { useComponentValue } from "@latticexyz/react";
import { useMUD } from "../../../store";

export function useSpectator() {
  const {
    localLayer: {
      singletonEntity,
      components: { Spectator },
    },
  } = useMUD();

  const isSpectator = useComponentValue(Spectator, singletonEntity);

  return isSpectator;
}
