import { useMUD } from "../../store";
import { Entity, Has, HasValue, getComponentValue, getComponentValueStrict, runQuery } from "@latticexyz/recs";
import { useEntityQuery } from "@latticexyz/react";
import { DisplayNameWithLink } from "../amalgema-ui/CreatedBy";
import { toEthAddress } from "@latticexyz/utils";
import { Mana } from "./Theme/SkyStrife/Mana";
import { PromiseButton } from "./hooks/PromiseButton";
import { Hex } from "viem";

const DAY = 86400000;

// The top players are the ones that have been set as recipient (ie. joined matches) the most
export function TopPlayers() {
  const {
    networkLayer: {
      components: { Match, MatchConfig, Recipient },
      executeSystemWithExternalWallet,
    },
  } = useMUD();

  const recipients = useEntityQuery([Has(Recipient)])
    .filter((entity) => {
      const matchId = getComponentValue(Match, entity);
      const matchEntity = [...runQuery([Has(MatchConfig), HasValue(Match, { value: matchId?.value })])][0];
      if (!matchEntity) return false;
      const matchConfig = getComponentValue(MatchConfig, matchEntity);
      if (!matchConfig) return false;
      const { startTime } = matchConfig;

      return startTime * 1000n > Date.now() - DAY;
    })
    .map((entity) => getComponentValueStrict(Recipient, entity).value);

  const counts: Record<string, number> = {};
  recipients.forEach((s) => {
    counts[s] = counts[s] ? counts[s] + 1 : 1;
  });

  const rankings = Object.entries(counts);
  rankings.sort((a, b) => b[1] - a[1]);

  return (
    <div>
      <table className="w-full text-lg text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xl text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Address</th>
            <th>Matches joined (24 hours)</th>
            <th>Owed</th>
          </tr>
        </thead>
        <tbody>
          {rankings.map(([entity, count], i) => {
            const account = toEthAddress(entity);
            const owed =
              i === 0
                ? 600000000000000000000n
                : i === 1
                ? 300000000000000000000n
                : i === 2
                ? 100000000000000000000n
                : 0n;

            return (
              <tr key={entity}>
                <th>{i + 1}</th>
                <th>
                  <DisplayNameWithLink entity={entity as Entity} />
                </th>
                <th>{account}</th>
                <th>{count}</th>
                <th>
                  <PromiseButton
                    buttonType="tertiary"
                    promise={() =>
                      executeSystemWithExternalWallet({
                        systemCall: "transfer",
                        args: [[account as Hex, owed]],
                      })
                    }
                  >
                    Send <Mana amount={owed} />
                  </PromiseButton>
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
