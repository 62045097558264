import createStore from "zustand/vanilla";
import createReactStore from "zustand";
import IWorldAbi from "contracts/out/IWorld.sol/IWorld.abi.json";
import { NetworkLayer } from "./layers/Network";
import { PhaserLayer } from "./layers/Renderer/Phaser";
import { HeadlessLayer } from "./layers/Headless";
import { LocalLayer } from "./layers/Local";
import { defineSystem, getComponentValue, getComponentValueStrict, runQuery } from "@latticexyz/recs";
import { Account, Address, Chain, GetContractReturnType, Hex, PublicClient, Transport, WalletClient } from "viem";

export type ContractType = GetContractReturnType<
  typeof IWorldAbi,
  PublicClient<Transport, Chain>,
  WalletClient<Transport, Chain, Account>,
  Address
>;

export type Store = {
  networkLayer: NetworkLayer | null;
  headlessLayer: HeadlessLayer | null;
  localLayer: LocalLayer | null;
  phaserLayer: PhaserLayer | null;
  externalWalletClient: WalletClient | null;
  externalWorldContract: ContractType | null;
  transactions: Hex[];
};

export type UIStore = {
  networkLayer: NetworkLayer;
  headlessLayer: HeadlessLayer;
  localLayer: LocalLayer;
  phaserLayer: PhaserLayer;
  externalWalletClient: WalletClient | null;
  externalWorldContract: ContractType | null;
  transactions: Hex[];
};

export const store = createStore<Store>(() => ({
  networkLayer: null,
  headlessLayer: null,
  localLayer: null,
  phaserLayer: null,
  externalWalletClient: null,
  externalWorldContract: null,
  transactions: [],
}));

export const useStore = createReactStore(store);

export const useMUD = () => {
  const { networkLayer, headlessLayer, localLayer, phaserLayer, externalWalletClient, externalWorldContract } =
    useStore();

  if (networkLayer === null || headlessLayer === null || localLayer === null || phaserLayer === null) {
    throw new Error("Store not initialized");
  }

  if ((window as any).layers === undefined) {
    (window as any).layers = {
      networkLayer,
      headlessLayer,
      localLayer,
      phaserLayer,
    };

    (window as any).components = {
      ...networkLayer.components,
      ...headlessLayer.components,
      ...localLayer.components,
      ...phaserLayer.components,
    };

    (window as any).ecs = {
      getComponentValue,
      getComponentValueStrict,
      runQuery,
      defineSystem,
    };
  }

  return {
    networkLayer,
    headlessLayer,
    localLayer,
    phaserLayer,
    externalWalletClient,
    externalWorldContract,
  } as UIStore;
};

export const useAmalgema = () => {
  const { networkLayer, externalWalletClient, externalWorldContract, transactions } = useStore();

  if (networkLayer === null) {
    throw new Error("Network layer not initialized");
  }

  return { ...networkLayer, externalWalletClient, externalWorldContract, transactions };
};
