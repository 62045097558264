import { useEffect } from "react";
import { useAmalgema, useMUD } from "../../store";
import { drip } from "../../mud/faucet";

export function BurnerWalletDrip() {
  const {
    networkLayer: {
      network: {
        publicClient,
        networkConfig: { faucetServiceUrl },
        walletClient,
      },
    },
  } = useMUD();

  const { externalWalletClient } = useAmalgema();

  useEffect(() => {
    if (!walletClient || !faucetServiceUrl || !walletClient.account) return;

    drip(walletClient.account.address, faucetServiceUrl, publicClient);
  }, [walletClient, faucetServiceUrl, publicClient]);

  // drip the external wallet in case people go directly to the match lobby
  useEffect(() => {
    if (!externalWalletClient || !faucetServiceUrl || !externalWalletClient.account) return;

    drip(externalWalletClient.account.address, faucetServiceUrl, publicClient);
  }, [externalWalletClient, faucetServiceUrl, publicClient]);

  return <></>;
}
