import { Assets } from "../../layers/Renderer/Phaser/phaserConstants";
import { useMUD } from "../../store";
import { useCurrentPlayer } from "./hooks/useCurrentPlayer";
import { usePlayerGold } from "./hooks/usePlayerGold";

export const GoldAmount = () => {
  const {
    phaserLayer: {
      scenes: {
        Main: {
          config: { assets },
        },
      },
    },
  } = useMUD();

  const currentPlayer = useCurrentPlayer();
  const { amount, regen } = usePlayerGold(currentPlayer);

  return (
    <div className="flex flex-row items-center text-[#CEA82C]">
      <div className="flex flex-row text-3xl w-full items-center">
        <img className="mr-2 w-[24px] h-[24px]" src={assets[Assets.Gold].path} />
        <div className="text-ss-text-gold text-2xl">{amount}g</div>
      </div>
      <div className="w-full text-right text-ss-text-light">+{regen}g/turn</div>
    </div>
  );
};
