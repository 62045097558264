import { Link, Caption, OverlineSmall } from "../ui/Theme/SkyStrife/Typography";
import { useAmalgema } from "../../store";
import { useEntityQuery } from "@latticexyz/react";
import { Has, Not } from "@latticexyz/recs";
import { useSummonIslandModal } from "./SummonIsland";
import { MatchCard } from "./MatchCard";

export function OpenOrPendingMatches() {
  const {
    components: { MatchConfig, Match, MatchReady },
    utils: { matchIsLive },
  } = useAmalgema();

  const { setModalOpen, modal } = useSummonIslandModal();

  const openMatches = useEntityQuery([Has(Match), Has(MatchConfig), Has(MatchReady)]);
  const pendingMatches = useEntityQuery([Has(Match), Has(MatchConfig), Not(MatchReady)]);
  const allMatches = openMatches.concat(pendingMatches);

  const openOrPendingMatches = allMatches.filter((entity) => !matchIsLive(entity));

  return (
    <div>
      {modal}
      <OverlineSmall className="mb-4 uppercase">Summoning Islands ({openOrPendingMatches.length})</OverlineSmall>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {openOrPendingMatches.length === 0 && (
          <div className="w-full h-[200px] border border-ss-stroke rounded flex flex-col justify-around bg-ss-bg-0 col-span-3">
            <Caption className="text-center">
              No matches currently available.<br></br>
              Free matches are summoned 3x/daily at 3:00am GMT+1, 11:00am GMT+1, and 7:00pm GMT+1.
              <br></br>
              <div
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Or, you can <Link>summon an island</Link>.
              </div>
            </Caption>
          </div>
        )}

        {openOrPendingMatches.map((i) => {
          return (
            <div key={`open-match-${i}`}>
              <MatchCard matchEntity={i} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
