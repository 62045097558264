import { Entity } from "@latticexyz/recs";
import { useAmalgema } from "../../store";
import { useComponentValue } from "@latticexyz/react";
import { PendingMatch } from "./PendingMatch";
import { LiveMatch } from "./LiveMatch";
import { OpenMatch } from "./OpenMatch";
import { HistoricalMatch } from "./HistoricalMatch";

export function MatchCard({ matchEntity }: { matchEntity: Entity }) {
  const {
    components: { MatchReady, Match, MatchFinished },
    utils: { matchIsLive },
  } = useAmalgema();

  const matchReady = useComponentValue(MatchReady, matchEntity);
  const matchFinished = useComponentValue(MatchFinished, matchEntity);
  const matchId = useComponentValue(Match, matchEntity)?.value;

  const isPending = Boolean(!matchReady);
  const isOpen = Boolean(matchReady) && !matchIsLive(matchEntity);
  const isLive = !matchFinished && Boolean(matchIsLive(matchEntity));

  if (!matchId) return <></>;

  return (
    <>
      {isPending && <PendingMatch matchEntity={matchEntity} />}
      {isOpen && <OpenMatch matchEntity={matchEntity} />}
      {isLive && <LiveMatch matchId={matchId} />}
      {matchFinished && <HistoricalMatch matchEntity={matchEntity} />}
    </>
  );
}
