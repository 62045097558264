import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { useState } from "react";
import { addressToEntityID } from "../../mud/setupNetwork";
import { useAmalgema } from "../../store";
import { Hex } from "viem";
import { useEffect } from "react";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Has, getComponentValue } from "@latticexyz/recs";
import { Body, Heading } from "../ui/Theme/SkyStrife/Typography";
import { NetworkStatus } from "./NetworkStatus";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { PromiseButton } from "../ui/hooks/PromiseButton";
import { AnalyticsConsentForm } from "../AnalyticsConsentForm";

function FullscreenModal({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        background: "rgba(24, 23, 16, 0.65)",
        zIndex: 100,
      }}
      className="fixed top-0 left-0 w-screen h-screen flex flex-col justify-around"
    >
      <Card primary className="bg-ss-bg-1 flex flex-col justify-center mx-auto p-8 w-fit w-[624px]">
        {children}
      </Card>
    </div>
  );
}

export function ChooseUsernameModal() {
  const {
    components: { Name },
    externalWalletClient,
    externalWorldContract,
    executeSystemWithExternalWallet,
  } = useAmalgema();

  const address = externalWalletClient?.account?.address;
  const name = useComponentValue(Name, addressToEntityID(address || ("0x00" as Hex)))?.value;
  const allNames = useEntityQuery([Has(Name)]).map((e) => getComponentValue(Name, e)?.value);

  const [visible, setVisible] = useState(false);
  const [skip, setSkip] = useState(false);
  const [newName, setNewName] = useState(name ?? "");

  const nameTaken = allNames.includes(newName);

  useEffect(() => {
    if (!externalWalletClient) return;

    if (skip || (name && name.length > 0)) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [externalWalletClient, name, skip]);

  useEffect(() => {
    if (newName.length > 32) setNewName(newName.slice(0, 32));
  }, [newName]);

  if (!externalWorldContract || !externalWalletClient) return;

  let disabledMessage = "";
  if (nameTaken) {
    disabledMessage = "Name taken";
  } else if (newName.length === 0) {
    disabledMessage = "Save and Continue";
  }

  const disabled = nameTaken || newName.length === 0;

  return (
    <>
      {visible && (
        <FullscreenModal>
          <div className="flex justify-between items-center">
            <Heading className="uppercase">choose a name</Heading>
            <NetworkStatus />
          </div>
          <Body>Set a name to be associated with your wallet address.</Body>

          <div className="h-8" />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!disabled) {
                executeSystemWithExternalWallet({
                  systemCall: "setName",
                  args: [[newName]],
                });
              }
            }}
            className="flex flex-col items-start"
          >
            <label htmlFor="username" className="text-ss-text-x-light uppercase">
              Username
            </label>
            <div className="h-1" />
            <input
              id="username"
              className="bg-ss-bg-0 rounded border border-ss-stroke w-full px-3 py-2 shadow-ss-small"
              placeholder="Enter a username"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </form>

          <AnalyticsConsentForm />
          <div className="h-6" />

          <div className="flex">
            <Button
              buttonType="tertiary"
              className="uppercase w-1/3"
              onClick={() => {
                setSkip(true);
              }}
            >
              Skip
            </Button>

            <div className="w-4" />

            <PromiseButton
              buttonType="primary"
              disabled={disabled}
              promise={() => {
                return executeSystemWithExternalWallet({
                  systemCall: "setName",
                  args: [[newName]],
                });
              }}
              className="uppercase grow"
            >
              {disabledMessage || "Save and Continue"}
            </PromiseButton>
          </div>
        </FullscreenModal>
      )}
    </>
  );
}
