import { useEffect, useRef, useState } from "react";
import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { getComponentValue, Has, HasValue, setComponent } from "@latticexyz/recs";
import { ContractType, useAmalgema, useMUD } from "../../../store";
import { useCurrentPlayer } from "../hooks/useCurrentPlayer";
import { Button } from "../Theme/SkyStrife/Button";
import { Heading, OverlineSmall } from "../Theme/SkyStrife/Typography";
import { Hex } from "viem";
import { addressToEntityID } from "../../../mud/setupNetwork";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { useMatchInfo } from "../hooks/useMatchInfo";
import { CreatedBy } from "../../amalgema-ui/CreatedBy";
import { SendTxButton } from "../hooks/SendTxButton";
import { MATCH_URL } from "../../links";

function ChainSVG({ onClick }: { onClick?: () => void }) {
  const [hover, setHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseDown={() => setHover(false)}
      onClick={onClick}
      style={{
        transform: hover ? "scale(1.1)" : "",
        transition: "transform 0.1s ease-in-out",
      }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1294_6801)">
        <path
          d="M8.79335 5.79202C9.22657 5.99886 9.60382 6.30664 9.89342 6.68951C10.183 7.07238 10.3765 7.51916 10.4576 7.99232C10.5388 8.46548 10.5052 8.9512 10.3596 9.40867C10.2141 9.86614 9.96093 10.282 9.62135 10.6214L6.62135 13.6213C6.05874 14.184 5.29567 14.5 4.50002 14.5C3.70436 14.5 2.9413 14.184 2.37869 13.6213C1.81607 13.0587 1.5 12.2957 1.5 11.5C1.5 10.7044 1.81607 9.9413 2.37869 9.37868L3.55002 8.20735M12.45 7.79268L13.6213 6.62135C14.184 6.05874 14.5 5.29567 14.5 4.50002C14.5 3.70436 14.184 2.9413 13.6213 2.37869C13.0587 1.81607 12.2957 1.5 11.5 1.5C10.7044 1.5 9.9413 1.81607 9.37868 2.37869L6.37868 5.37868C6.03911 5.71802 5.78593 6.13389 5.64041 6.59137C5.49488 7.04884 5.46127 7.53456 5.5424 8.00772C5.62352 8.48087 5.81701 8.92765 6.10661 9.31053C6.39621 9.6934 6.77347 10.0012 7.20669 10.208"
          stroke="#5D5D4C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1294_6801">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const RegistrationForm = ({
  address,
  externalWorldContract,
}: {
  address: Hex;
  externalWorldContract: ContractType;
}) => {
  const {
    networkLayer: {
      network,
      components: { PlayerReady },
      utils: { getAvailableLevelSpawns },
      executeSystemWithExternalWallet,
    },
    localLayer: {
      components: { Spectator },
      singletonEntity,
    },
  } = useMUD();

  const {
    match,
    components: { Name, Match, MatchConfig },
    walletClient,
    worldContract,
  } = network;

  const name = useComponentValue(Name, addressToEntityID(address || ("0x00" as Hex)));
  const [newName, setNewName] = useState(name ? name.value : "");

  const [pendingTx, setPendingTx] = useState(false);

  const currentPlayer = useCurrentPlayer();
  const playerReadys = useEntityQuery([Has(PlayerReady), HasValue(Match, { value: match })]);
  const currentPlayerReady = Boolean(playerReadys.find((i) => i === currentPlayer?.player));

  const matchEntity = useEntityQuery([HasValue(Match, { value: match }), Has(MatchConfig)])[0];

  const otherNames = useEntityQuery([Has(Name)]).map((entity) => getComponentValue(Name, entity)?.value);
  const nameTaken = otherNames.includes(newName);
  const registerDisabled = newName.length === 0 || newName.length > 32 || (nameTaken && name?.value !== newName);

  const matchConfig = useComponentValue(MatchConfig, matchEntity);
  const levelId = matchConfig?.levelId;

  const availableSpawns = levelId ? getAvailableLevelSpawns(levelId, matchEntity as Hex) : [];

  const register = async () => {
    if (externalWorldContract) {
      // Used to later notify player of match start
      Notification.requestPermission();

      setPendingTx(true);

      try {
        await executeSystemWithExternalWallet({
          systemCall: "registerWithName",
          args: [[matchEntity as Hex, availableSpawns[0], newName, walletClient.account.address]],
        });
      } catch (e) {
        console.error(e);
      } finally {
        setPendingTx(false);
      }
    }
  };

  const readyButton = SendTxButton({
    buttonType: "secondary",
    children: "ready",
    className: "w-full",
    sendTx: () => {
      return worldContract.write.toggleReady([matchEntity as Hex]);
    },
    network,
  });

  const unReadyButton = SendTxButton({
    buttonType: "secondary",
    children: "unready",
    className: "w-full",
    sendTx: () => {
      return worldContract.write.toggleReady([matchEntity as Hex]);
    },
    network,
  });

  let disabledMessage = "";
  if (newName.length === 0) disabledMessage = "Join";
  if (newName.length > 32) disabledMessage = "Name too long";
  if (nameTaken) disabledMessage = "Name taken";

  let joinButtonText = "Join";
  if (registerDisabled) joinButtonText = disabledMessage;
  if (pendingTx) joinButtonText = "Joining...";

  if (!matchEntity) return <></>;

  if (!currentPlayer?.player && availableSpawns.length === 0) {
    return (
      <div className="text-ss-text-x-light flex row justify-around items-center">
        Match full
        <Button
          buttonType="tertiary"
          className="w-1/3"
          onClick={() => {
            setComponent(Spectator, singletonEntity, { value: true });
          }}
        >
          Spectate
        </Button>
      </div>
    );
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          register();
        }}
        className="w-full"
      >
        <div className="relative h-fit">
          <input
            type={"text"}
            className="w-full py-2 px-3 rounded border border-1 border-white bg-[#F4F3F1] flex flex-row"
            placeholder="Enter a name"
            disabled={Boolean(pendingTx || currentPlayer?.player)}
            value={newName}
            onChange={(e) => {
              let name = e.target.value;
              if (name.length > 32) name = name.slice(0, 32);

              setNewName(name);
              return false;
            }}
          ></input>

          <div
            style={{
              pointerEvents: "none",
              top: `calc(50% - 11px)`,
            }}
            className="absolute right-3 text-ss-text-x-light uppercase"
          >
            {address?.slice(0, 6)}...{address?.slice(-4)}
          </div>
        </div>
      </form>

      <div className="flex row">
        {!currentPlayer?.player && (
          <>
            <Button
              buttonType="tertiary"
              className="w-1/3"
              onClick={() => {
                setComponent(Spectator, singletonEntity, { value: true });
              }}
            >
              Spectate
            </Button>

            <div className="w-3"></div>
          </>
        )}

        {!currentPlayer?.player && (
          <div className="grow">
            {!pendingTx ? (
              <Button
                disabled={registerDisabled || pendingTx}
                buttonType="secondary"
                className="w-full"
                onClick={() => register()}
              >
                {joinButtonText}
              </Button>
            ) : (
              <Button disabled buttonType="secondary" className="text-2xl w-full">
                Joining...
              </Button>
            )}
          </div>
        )}

        {currentPlayer.player && !currentPlayerReady && <div className="grow">{readyButton}</div>}

        {currentPlayerReady && <div className="grow">{unReadyButton}</div>}
      </div>
    </div>
  );
};

export const JoinGame = () => {
  const {
    networkLayer: { network },
    localLayer: {
      components: { Spectator },
      singletonEntity,
    },
  } = useMUD();

  const matchLinkRef = useRef<HTMLInputElement>(null);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  useEffect(() => {
    if (showCopyNotification) {
      setTimeout(() => {
        setShowCopyNotification(false);
      }, 2_000);
    }
  }, [showCopyNotification]);

  const { match, initialiseWallet } = network;

  const { externalWalletClient, externalWorldContract } = useAmalgema();

  const matchInfo = useMatchInfo();

  const externalAccount = useAccount();
  let address = externalAccount.address;
  if (!address) address = externalWalletClient?.account?.address;

  useEffect(() => {
    if (!address) return;

    initialiseWallet(address);
  }, [address, initialiseWallet]);

  const matchName = `Match ${match}`;

  const spectate = useComponentValue(Spectator, singletonEntity)?.value;

  return (
    <div className="w-full">
      <div className="flex flex-row">
        <Heading>{matchName}</Heading>
        <div className="w-3"></div>
        <div className="flex flex-row items-center bg-[#F4F3F1] hover:bg-gray border border-1 border-[#DDDAD0] rounded py-1 px-1">
          <ChainSVG
            onClick={() => {
              if (matchLinkRef.current) {
                matchLinkRef.current.select();
                document.execCommand("copy");
                setShowCopyNotification(true);
              }
            }}
          />
        </div>
        <div className="w-3"></div>
        <input
          ref={matchLinkRef}
          className="bg-[#F4F3F1] w-24 text-ss-text-x-light"
          readOnly
          value={`${MATCH_URL}?match=${match}`}
        ></input>
        <div className="w-3"></div>
        {showCopyNotification && <div className="text-ss-text-x-light animate-bounce">Copied!</div>}
      </div>
      {matchInfo?.matchConfig?.createdBy && <CreatedBy createdBy={matchInfo.matchConfig.createdBy as Hex} />}

      <div className="h-4"></div>

      {!spectate && (
        <>
          {address && externalWorldContract ? (
            <RegistrationForm address={address} externalWorldContract={externalWorldContract} />
          ) : (
            <div>
              <OverlineSmall>register</OverlineSmall>
              <ConnectButton />
            </div>
          )}
          <div className="h-8"></div>
        </>
      )}
    </div>
  );
};
