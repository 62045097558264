import { Has, getComponentValue, getComponentValueStrict, runQuery } from "@latticexyz/recs";
import { NetworkLayer } from "../../../layers/Network";

export function getOldestMatchInWindow(layer: NetworkLayer, _blockNumber: bigint, window: bigint) {
  const {
    components: { Match, MatchSky, LatestMatch },
    network: { singletonEntity },
  } = layer;

  const blockNumber = _blockNumber > window ? _blockNumber - window : 0;
  const allMatches = runQuery([Has(MatchSky)]);

  let maxIndex = (getComponentValue(LatestMatch, singletonEntity)?.value ?? 0) + 1;
  let maxBlockNumber = BigInt(2) ** BigInt(256) - BigInt(1);

  for (const match of allMatches) {
    const matchBlockNumber = getComponentValueStrict(MatchSky, match).blockNumber;
    if (matchBlockNumber > blockNumber && matchBlockNumber < maxBlockNumber) {
      maxIndex = getComponentValueStrict(Match, match).value;
      maxBlockNumber = matchBlockNumber;
    }
  }

  return maxIndex;
}

const getReward = (cost: bigint, numberOfMatches: number) => {
  if (numberOfMatches < 111) {
    return 3n * cost;
  } else if (numberOfMatches < 222) {
    return 2n * cost;
  } else if (numberOfMatches < 333) {
    return (3n * cost) / 2n;
  } else if (numberOfMatches < 444) {
    return cost;
  } else if (numberOfMatches < 555) {
    return (9n * cost) / 10n;
  } else if (numberOfMatches < 666) {
    return (4n * cost) / 5n;
  } else if (numberOfMatches < 777) {
    return (3n * cost) / 5n;
  } else if (numberOfMatches < 888) {
    return (2n * cost) / 5n;
  } else if (numberOfMatches < 999) {
    return (1n * cost) / 5n;
  }

  return 0n;
};

export function getMatchRewardAtBlock(layer: NetworkLayer, blockNumber: bigint) {
  const {
    components: { SkyPoolConfig, LatestMatch },
  } = layer;

  const skypoolEntity = [...runQuery([Has(SkyPoolConfig)])][0];
  const skypoolConfig = getComponentValue(SkyPoolConfig, skypoolEntity);
  if (!skypoolConfig) return 0n;

  const { cost, window } = skypoolConfig;

  const oldestMatchIndex = getOldestMatchInWindow(layer, blockNumber, window);
  const latestMatchEntity = [...runQuery([Has(LatestMatch)])][0];
  const nextMatchIndex = (getComponentValue(LatestMatch, latestMatchEntity)?.value ?? 0) + 1;

  const numberOfMatches = nextMatchIndex - oldestMatchIndex;
  const reward = getReward(cost, numberOfMatches);

  return reward;
}
