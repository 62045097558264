import { useEntityQuery } from "@latticexyz/react";
import { Entity, Has } from "@latticexyz/recs";
import { Button, ButtonType } from "./Theme/Button";
import { Dispatch, SetStateAction, useState } from "react";
import { useMUD } from "../../store";
import { BrutalistButton } from "./Theme/BrutalistButton";
import { ClickWrapper } from "./Theme/ClickWrapper";
import { BrutalistCard } from "./Theme/BrutalistCard";
import { DisplayLevel } from "./DisplayLevel";
import { Hex, getAbiItem, getFunctionSelector, hexToString } from "viem";
import { resourceToHex } from "@latticexyz/common";

export const CreateMatch = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    networkLayer: {
      network: {
        worldContract,
        components: { LevelTemplates },
      },
    },
  } = useMUD();

  const [level, setLevel] = useState<Entity | undefined>();

  const allMaps = useEntityQuery([Has(LevelTemplates)]);
  const names = [...new Set(allMaps)].map((id) => {
    return { id, name: hexToString(id as Hex, { size: 32 }) };
  });

  return (
    visible && (
      <ClickWrapper className="fixed top-0 left-0 flex h-screen w-screen flex-col items-center justify-around bg-black bg-opacity-50">
        <BrutalistCard className="w-96 h-fit">
          <div className="p-8">
            <div className="w-full text-3xl text-left p-1">Create match</div>
            <div className="flex flex-col">
              <div className="flex flex-row justify-between p-1">
                <div>
                  <div className="text-xl">Map</div>
                  Map:
                  <select value={level} onChange={(e) => setLevel(e.target.value as Entity)}>
                    <option value={undefined}>Select a map</option>
                    {names.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Button
                className="m-1"
                disabled={!level || level === "Select a map"}
                buttonType={ButtonType.Primary}
                onClick={async () => {
                  await worldContract.write.createMatch([
                    level as Hex,
                    resourceToHex({ type: "system", namespace: "", name: "AllowAnySystem" }),
                    getFunctionSelector(getAbiItem({ abi: worldContract.abi, name: "isAllowed" })),
                  ]);
                  // TODO: waitForTransaction?
                  setVisible(false);
                }}
              >
                Create
              </Button>
            </div>

            {level && level !== "Select a map" && <DisplayLevel level={level} />}
            <BrutalistButton className="mt-6 w-full h-fit" onClick={() => setVisible(false)}>
              Back
            </BrutalistButton>
          </div>
        </BrutalistCard>
      </ClickWrapper>
    )
  );
};
