import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { Entity, Has, HasValue } from "@latticexyz/recs";
import { useMUD } from "../../store";
import { Card } from "./Theme/SkyStrife/Card";
import { Body, OverlineSmall } from "./Theme/SkyStrife/Typography";
import { useMatchRewards } from "../amalgema-ui/hooks/useMatchRewards";
import Color from "color";
import { Button } from "./Theme/SkyStrife/Button";
import { ClickWrapper } from "./Theme/ClickWrapper";
import { useState } from "react";
import { BASE_URL, FEEDBACK_URL } from "../links";
import { Mana } from "./Theme/SkyStrife/Mana";

function interleave(arr: any[], seperator: any) {
  return arr
    .reduce((acc, i) => {
      return [...acc, i, seperator];
    }, [])
    .slice(0, -1);
}

const suffix = (function () {
  const s = ["th", "st", "nd", "rd"];
  return function (n: number) {
    const d = (n | 0) % 100;
    return d > 3 && d < 21 ? "th" : s[d % 10] || "th";
  };
})();

const PlayerName = ({ entity }: { entity: Entity }) => {
  const {
    localLayer: {
      api: { getPlayerInfo },
    },
  } = useMUD();

  const playerInfo = getPlayerInfo(entity);

  if (!playerInfo) {
    return (
      <div className="rounded-lg px-3 py-1 font-medium text-ss-default h-8 flex flex-col justify-center w-[240px]"></div>
    );
  }

  const { name, playerColor } = playerInfo;

  return (
    <div
      style={{
        backgroundColor: Color(playerColor.color).lighten(0.5).hex(),
        border: `1px solid ${Color(playerColor.color).hex()}`,
      }}
      className="rounded-lg px-3 py-1 font-medium text-ss-default h-8 flex flex-col justify-center w-[240px]"
    >
      {name}
    </div>
  );
};

export const GameOutcome = () => {
  const {
    networkLayer: {
      components: { Match, MatchConfig, MatchFinished, MatchRanking },
      network: { match },
    },
    localLayer: {
      api: { getPlayerInfo },
    },
  } = useMUD();

  const [hide, setHide] = useState(false);

  const matchRewards = useMatchRewards(match);

  const matchEntity = useEntityQuery([Has(MatchConfig), HasValue(Match, { value: match })])[0];
  const finished = useComponentValue(MatchFinished, matchEntity);

  const ranking = useComponentValue(MatchRanking, matchEntity);
  if (!ranking) return <></>;

  const winnerInfo = getPlayerInfo(ranking.value[0] as Entity);

  return (
    <>
      {!hide && finished && finished.value && (
        <ClickWrapper className="fixed w-screen h-screen top-0 left-0 flex flex-col items-center justify-center bg-[#181710]/60">
          <div className="-mt-[240px]">
            <div className="relative h-[310px] overflow-hidden">
              <div className="absolute top-[215px] w-full text-center text-white text-5xl">
                {winnerInfo ? winnerInfo.name : ""} wins!
              </div>
              <img className="" src="/assets/victory-banner.png" />
            </div>

            <Card
              style={{
                zIndex: 120,
              }}
              className="w-[640px] p-8"
            >
              <div className="flex justify-between">
                <div className="w-[56px]">
                  <OverlineSmall className="mb-3 text-ss-text-x-light">Rank</OverlineSmall>
                  {interleave(
                    ranking.value.map((_entity, i) => {
                      return (
                        <Body key={`rank-${i}`} className="h-8 text-ss-text-default">
                          {i + 1}
                          {suffix(i + 1)}
                        </Body>
                      );
                    }),
                    <div className="h-2" />
                  )}
                </div>

                <div className="w-4" />

                <div className="grow">
                  <OverlineSmall className="mb-3 text-ss-text-x-light">Player</OverlineSmall>
                  {interleave(
                    ranking.value.map((entity, i) => {
                      return <PlayerName key={`name-${i}`} entity={entity as Entity} />;
                    }),
                    <div className="h-2" />
                  )}
                </div>

                <div className="w-4" />

                <div className="w-[80px]">
                  <OverlineSmall className="mb-3 text-ss-text-x-light">Reward</OverlineSmall>
                  {interleave(
                    ranking.value.map((_entity, i) => {
                      return <Mana key={`reward-${i}`} className="h-8" amount={matchRewards[i]?.value ?? 0} />;
                    }),
                    <div className="h-2" />
                  )}
                </div>
              </div>

              <div className="h-8" />

              <a href={FEEDBACK_URL} className="w-full" rel="noreferrer" target="_blank">
                <Button className="w-full" buttonType="secondary">
                  Submit Feedback
                </Button>
              </a>

              <div className="h-3" />

              <div className="flex">
                <a className="grow" href={BASE_URL}>
                  <Button className="w-full" buttonType="tertiary">
                    Back to Menu
                  </Button>
                </a>

                <div className="w-3" />

                <Button className="grow" onClick={() => setHide(true)} buttonType="tertiary">
                  View Island
                </Button>
              </div>
            </Card>
          </div>
        </ClickWrapper>
      )}

      {hide && finished && finished.value && (
        <div className="fixed w-screen h-screen flex flex-col -ml-8 -mt-8">
          <div className="h-3/4" />

          <ClickWrapper className="mx-auto flex flex-row">
            <a href={BASE_URL}>
              <Button buttonType="primary" className="text-3xl px-8 py-6">
                Back to Menu
              </Button>
            </a>

            <div className="w-16" />

            <Button buttonType="primary" className="text-3xl px-8 py-6" onClick={() => setHide(false)}>
              View Results
            </Button>
          </ClickWrapper>
        </div>
      )}
    </>
  );
};
