import { useEffect, useState } from "react";

export function MouseTooltip({ showTooltip, children }: { showTooltip: boolean; children: React.ReactNode }) {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [delayedShowTooltip, setDelayedShowTooltip] = useState(false);

  // wait a bit before showing the tooltip so that it doesn't flicker
  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setDelayedShowTooltip(true);
      }, 25);
    } else {
      setDelayedShowTooltip(false);
    }
  }, [showTooltip]);

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      setTooltipPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  return (
    <>
      {delayedShowTooltip && (
        <div style={{ left: tooltipPosition.x + 24, top: tooltipPosition.y }} className="absolute z-10">
          {children}
        </div>
      )}
    </>
  );
}
