import { useStore } from "../../store";
import { Layer } from "./Layer";
import { Admin } from "./Admin";
import { Factory } from "./Factory";
import { GameOutcome } from "./GameOutcome";
import { Leaderboard } from "./Leaderboard";
import { TurnInfo } from "./TurnInfo";
import { OptionsBar } from "./OptionsBar";
import { PreGame } from "./PreGame";
import { Browser } from "./Browser";
import { SyncStatus } from "./SyncStatus";
import { PlayerNameHover } from "./PlayerNameHover";
import { TransactionDebug } from "./TransactionDebug";
import { BurnerWalletDrip } from "./BurnerWalletDrip";

export const UIRoot = () => {
  const layers = useStore((state) => {
    return {
      networkLayer: state.networkLayer,
      headlessLayer: state.headlessLayer,
      localLayer: state.localLayer,
      phaserLayer: state.phaserLayer,
    };
  });

  if (!layers.networkLayer || !layers.headlessLayer || !layers.localLayer || !layers.phaserLayer) return <></>;

  return (
    <div>
      <Layer style={{ inset: "24px" }}>
        {layers.networkLayer.network.match === -1 ? (
          <Browser />
        ) : (
          <div>
            <Leaderboard />
            <GameOutcome />
            <Factory />

            <PreGame />

            <TurnInfo />
          </div>
        )}
        <OptionsBar />
        <PlayerNameHover />
        <SyncStatus />
        <BurnerWalletDrip />

        <TransactionDebug />
        <Admin />
      </Layer>
    </div>
  );
};
