import { EnergySurge } from "./EnergySurge";
import { GoldAmount } from "./GoldAmount";
import { useMatchInfo } from "./hooks/useMatchInfo";
import { useSpectator } from "./hooks/useSpectator";
import { Card } from "../ui/Theme/SkyStrife/Card";

export function TurnInfo() {
  const isSpectator = useSpectator();

  const { matchStarted, matchFinished } = useMatchInfo();
  if (!matchStarted || matchFinished) return <></>;

  return (
    <div className="absolute left-1/2 -translate-x-1/2">
      {!isSpectator && (
        <Card className="w-full border-[#181710]">
          <GoldAmount />
        </Card>
      )}
      <div className="h-1"></div>
      <div className="h-fit w-full flex flex-col items-baseline">
        <EnergySurge />
      </div>
    </div>
  );
}
