import { Entity, getComponentValue } from "@latticexyz/recs";
import { useAmalgema } from "../../store";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Body, Heading } from "../ui/Theme/SkyStrife/Typography";
import { useComponentValue } from "@latticexyz/react";
import { CreatedBy } from "./CreatedBy";
import { Hex, formatEther } from "viem";
import { useMatchRewards } from "./hooks/useMatchRewards";

export function HistoricalMatch({ matchEntity }: { matchEntity: Entity }) {
  const {
    components: { Match, MatchConfig, MatchRanking, Recipient, Name },
  } = useAmalgema();

  const matchId = useComponentValue(Match, matchEntity)?.value ?? 0;
  const matchConfig = useComponentValue(MatchConfig, matchEntity);
  const matchRanking = useComponentValue(MatchRanking, matchEntity);
  const matchRewards = useMatchRewards(matchId);

  const winner = (matchRanking?.value[0] ?? "") as Entity;
  const winnerWallet = getComponentValue(Recipient, winner)?.value;
  const winnerName = (winner && getComponentValue(Name, winnerWallet as Entity)?.value) ?? "Unknown";

  const startTime = matchConfig?.startTime ?? 0n;

  if (!matchEntity) return <></>;

  return (
    <Card primary={false}>
      <div className="flex flex-row justify-between items-center">
        <span className="flex flex-row items-center">
          <div className="text-lg">🏆</div>
          <div className="w-2" />
          <Heading className="normal-case">Island</Heading>
        </span>
        <Body className="text-ss-text-x-light">#{matchId}</Body>
      </div>

      {matchConfig?.createdBy && <CreatedBy createdBy={matchConfig.createdBy as Hex} />}

      <div className="h-4" />
      <Body>Match played at {new Date(Number(startTime * 1000n)).toLocaleString()}</Body>

      <div className="h-4" />
      <Body>
        {winnerName} won the match and earned{" "}
        <span className="text-ss-text-highlight">{formatEther(matchRewards[0].value)}</span>🔮.
      </Body>

      <div className="h-3"></div>
    </Card>
  );
}
