import { defineRxSystem, getComponentValue, setComponent } from "@latticexyz/recs";
import { LocalLayer } from "../../types";

export function createMatchStartSystem(layer: LocalLayer) {
  const {
    parentLayers: {
      network: {
        network: {
          components: { MatchConfig },
          clock,
          match,
        },
        api: { getMatchEntity },
      },
    },
    components: { MatchStarted },
    world,
  } = layer;

  defineRxSystem(world, clock.time$, (time) => {
    const matchEntity = getMatchEntity(match);
    if (!matchEntity) return;

    const startTime = getComponentValue(MatchConfig, matchEntity)?.startTime;
    if (!startTime) return;

    if (time / 1000 >= startTime) {
      setComponent(MatchStarted, matchEntity, { value: true });
    }
  });
}
