import { useEntityQuery } from "@latticexyz/react";
import { useAmalgema } from "../../../store";
import { Entity, Has, HasValue, getComponentValue, getComponentValueStrict } from "@latticexyz/recs";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { isDefined } from "@latticexyz/common/utils";

export function useMatchRewards(matchId: number) {
  const {
    components: { MatchReward, MatchConfig, Match, TokenMetadata },
  } = useAmalgema();

  const matchEntity = useEntityQuery([HasValue(Match, { value: matchId }), Has(MatchConfig)])[0];
  const rewards = useEntityQuery([Has(MatchReward)])
    .map((key) => {
      const { entity, rank } = decodeEntity(MatchReward.metadata.keySchema, key);
      if (entity !== matchEntity) return;

      const { token, value } = getComponentValueStrict(MatchReward, key);
      const tokenMetadata = getComponentValue(TokenMetadata, token as Entity);
      if (!tokenMetadata) return;

      const { emoji } = tokenMetadata;

      return { rank, value, emoji };
    })
    .filter(isDefined);

  return rewards;
}
