import { defineSystem, Entity, getComponentValueStrict, Has, UpdateType } from "@latticexyz/recs";
import { PhaserLayer } from "../..";
import { StructureTypes } from "../../../../Network";

export function createTintNoStaminaSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Stamina, UnitType, StructureType },
      },
      headless: {
        components: { LocalStamina, Depleted },
        api: { getCurrentStamina },
      },
      local: {
        components: { LocalPosition },
      },
    },
    scenes: {
      Main: { objectPool },
    },
  } = layer;

  const tintStamina = (entity: Entity) => {
    const spriteObj = objectPool.get(entity, "Sprite");
    spriteObj.setComponent({
      id: "stamina-tint",
      once: (sprite) => {
        const currentStamina = getCurrentStamina(entity);

        if (currentStamina === 0) {
          sprite.setTint(0x808080);
        } else {
          sprite.clearTint();
        }
      },
    });
  };

  defineSystem(world, [Has(UnitType), Has(Stamina), Has(LocalStamina), Has(LocalPosition)], ({ entity, type }) => {
    if (type === UpdateType.Exit) return;
    tintStamina(entity);
  });

  defineSystem(world, [Has(UnitType), Has(Stamina), Has(LocalPosition)], ({ entity, type }) => {
    if (type === UpdateType.Exit) return;
    tintStamina(entity);
  });

  defineSystem(world, [Has(UnitType), Has(Stamina), Has(LocalStamina), Has(LocalPosition)], ({ entity, type }) => {
    if (type === UpdateType.Exit) return;
    tintStamina(entity);
  });

  defineSystem(world, [Has(Depleted), Has(LocalPosition), Has(StructureType)], ({ entity, type }) => {
    if (type === UpdateType.Exit) return;
    if (getComponentValueStrict(StructureType, entity).value === StructureTypes.SpawnSettlement) return;

    tintStamina(entity);
  });
}
